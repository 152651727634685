<div *ngIf="showMask" class="mask d-flex justify-content-center align-items-center bg-white">
    <img src="/assets/img/loader.gif" alt="" class="">
    <span class="text-lg">{{ 'loading_data' | translate }}... </span>
</div>
<div class="main-container justify-content-center">
    <div class="container">

        <!-- Outer Row -->
        <div class="row justify-content-center">

            <div class="col-xl-10 col-lg-12 col-md-9">
                <div class="card o-hidden border-0 shadow-lg my-5">
                    <div class="card-body p-0">
                        <!-- Nested Row within Card Body -->
                        <div class="row">
                            <div class="col-lg-6 d-none d-lg-block bg-login-image"></div>
                            <div class="col-lg-6">
                                <div class="p-5">
                                    <div class="text-center pb-4">
                                        <img src="/assets/img/default_logo.png" alt="logo AfrikBiz" class="img-fluid mw-50">
                                    </div>
                                    <div class="text-center">
                                        <h1 class="h4 text-gray-900 mb-4"> {{'welcome' | translate}} </h1>
                                    </div>

                                    <form class="user" *ngIf="showOTPForm" >
                                      <div class="form-group" *ngIf="phoneNumber">
                                        <label for="phoneNumber">{{'will_receive_sms' | translate}} </label>
                                        <input type="tel" value="{{phoneNumber.protectedPhone()}}" [disabled] = "true" minlength="5" maxlength="15" class="form-control form-control-user mr-3"  id="loginPhone">
                                      </div>

                                      <div id="recaptcha-container" *ngIf="isFirebase" ></div>

                                      <div class="form-group">
                                        <label for="code" *ngIf="isSMSAuth">{{message}}</label><br>
                                        <label for="code" *ngIf="isFirebase">{{'verif_code' | translate}}</label><br>
                                        <input type="tel" maxlength="6"  class="form-control form-control-user mr-3" placeholder="OTP Code" [ngModelOptions]="{standalone: true}" id="loginOTP" [(ngModel)]="loginOTP" >
                                        <span *ngIf="incorrectOTP" class="text-xs text-danger font-weight-bold">{{'auth_code_error' | translate}} </span>
                                      </div>

                                      <button (click)="verifyLoginCode()" *ngIf="isFirebase" class="btn btn-primary btn-user btn-block" [disabled] = "!(loginOTP?.length > 0)">{{'verify' | translate}}</button>

                                      <button (click)="checkOTP()" *ngIf="isSMSAuth" class="btn btn-primary btn-user btn-block"  [disabled] = "!(loginOTP?.length > 0)" >{{ 'verify' | translate}} </button>
                                      <hr>
                                    </form>

                                    <form class="user" [formGroup]="passwordForm" (ngSubmit)="onPasswordChange()"  *ngIf="isFirstLogin" >
                                      <div class="text-center pb-4">
                                        <span class="font-weight-bold">{{'please_change_pass' | translate }}</span>
                                      </div>
                                      <div class="form-group">
                                        <input type="password" placeholder="{{'password' | translate}}"  formControlName="newPassword" class="form-control form-control-user mr-3">
                                        <span class="text-xs">{{'at_least_digits' | translate}}</span>
                                      </div>
                                      <div class="form-group">
                                        <input type="password" placeholder="{{'confirmPassword' | translate}}"  formControlName="passwordConfirm" class="form-control form-control-user mr-3">
                                        <span *ngIf="passwordError" class="text-xs text-danger">{{'password_no_match' | translate}}</span>
                                      </div>

                                      <button value="submit" class="btn btn-primary btn-user btn-block"  [disabled] = "!passwordForm.valid || loading" >{{ 'save' | translate}} </button>
                                      <hr>
                                    </form>


                                    <form [formGroup]="loginForm" (ngSubmit)="onLogin(null)" class="user" *ngIf="!(showOTPForm || isFirstLogin)" >
                                      <div class="form-group">
                                        <input type="email" placeholder="{{'email' | translate}}"  formControlName="email" class="form-control form-control-user mr-3">
                                      </div>
                                      <div class="form-group">
                                        <input type="password" placeholder="{{'password' | translate}}"  formControlName="password" class="form-control form-control-user mr-3">
                                      </div>

                                      <button value="submit" class="btn btn-primary btn-user btn-block" [disabled] = "!loginForm.valid || loading" >{{ 'Se connecter' | translate}} </button>
                                    </form>
                                    <div class="user text-center py-3"> <span class="text-danger font-weight-bold" >{{general_failure | translate}}</span>  </div>
                                    <div class="user text-center pt-3"> <a  [routerLink] ="" (click) = "cancelAll()" >{{'cancel2' | translate}}</a>  </div>
                                    <div class="text-center">
                                        <img src="/assets/img/load.gif" *ngIf="loading"  class="img-fluid"  width="50" >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </div>
</div>

<div class="mt-5 pt-5 pb-5 footer">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-xs-12 about-company">
                <h2>AfrikPay</h2>
                <p class="pr-5 text-gray-800">Make life simple </p>
                <p><a href="#"><i class="fab fa-facebook-square mr-1"></i></a><a href="#"><i class="fab fa-linkedin"></i></a></p>
            </div>
            <div class="col-lg-3 col-xs-12 links">
                <h4 class="mt-lg-0 mt-sm-3">Privacy</h4>
                <ul class="m-0 p-0">
                  <!--   <li>- <a href="#">Lorem ipsum</a></li>
                    <li>- <a href="#">Nam mauris velit</a></li>-->
                </ul>
            </div>
            <div class="col-lg-4 col-xs-12 location">
                <h4 class="mt-lg-0 mt-sm-4">Location</h4>
                <p>Douala logpom</p>
                <p class="mb-0"><i class="fa fa-phone mr-3"></i>(237) 658 880 708</p>
                <p><i class="fa fa-envelope-o mr-3"></i>contact@afrikpay.com</p>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col copyright">
                <p class=""><small class="text-white-50">© 2019. All Rights Reserved.</small></p>
            </div>
        </div>
    </div>
</div>
