import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { User } from 'src/classes/User';
import { Order } from 'src/classes/Order';
import { AuthService } from 'src/app/auth.service';
import { StoreService } from 'src/app/store.service';

@Component({
  selector: 'app-table-pending-payment',
  templateUrl: './table-pending-payment.component.html',
  styleUrls: ['./table-pending-payment.component.css']
})
export class TablePendingPaymentComponent implements OnInit {

    user: User;
    @Input() pendingPaymentOrders = new Array<Order>();
    @Input() dateLocale : string;
    @Output() selectOrder: EventEmitter<Order> = new EventEmitter();

    constructor(private store: StoreService) { }

    ngOnInit() {
        this.user = this.store.getUserData();
    }

    showOrder(order: Order) {
        this.selectOrder.emit(order);
    }
}
