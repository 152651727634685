import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor() { }

  getIsLoggedIn() {
    if (localStorage.getItem('IS_LOGGED_IN') === 'TRUE') { return true; }
    return false;
  }

  setId(id: any) {
    localStorage.setItem('ID', id);
  }

  getId() {
    return localStorage.getItem('ID');
  }

  logout() {
    localStorage.clear();
  }

  saveUserData(data: any) {
    localStorage.setItem('USER', JSON.stringify(data));
    localStorage.setItem('IS_LOGGED_IN', 'TRUE');
  }

  getUserData() {
      return JSON.parse(localStorage.getItem('USER'));
  }
}
