import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { User } from 'src/classes/User';
import { StoreService } from '../store.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  otherLang: string;
  user: User;
  loading = true;
  balance : number;

  constructor(private translate: TranslateService, private router: Router, private auth : AuthService,
    private store: StoreService) { }

  ngOnInit() {
    this.otherLang = (this.translate.getDefaultLang() === 'en') ? 'fr' : 'en';
    this.user =  new User(this.store.getUserData()) ;
    this.showBalance();
  }

  useLanguage(language: string) {

    if ( !(localStorage.getItem('lang') === language)) {
        this.translate.setDefaultLang(language);
        localStorage.setItem('lang', language);
        this.otherLang = (language === 'en') ? 'fr' : 'en';
        window.location.href = environment.appConfig.siteUrl + this.router.url;
    }
  }

  showBalance(){
    this.auth.getBalance().subscribe((data : any) => {
      this.loading = false;
      if(data.code == 200){
        this.balance = data.result.reservebalance;
      }else {
        if(data.code == 401){
          this.router.navigateByUrl("logout");
        }else{
          alert("Error code : "+data.code+" - Unable to get your balance")
        }
      }
    }, (error) => {
      this.loading = false;
      alert("Unknown error! Unable to get your balance - Kindly check your internet")
    })
  }




}
