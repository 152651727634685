<!-- Page Wrapper -->
<div id="wrapper">
  <!-- Sidebar -->
  <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled" id="accordionSidebar">
    <app-sidebar></app-sidebar>
  </ul>

  <!-- Content Wrapper -->
  <div id="content-wrapper" class="d-flex flex-column">

    <!-- Main Content -->
    <div id="content">

      <!-- Topbar -->
      <app-header></app-header>
      <div class="container-fluid">
        <!-- Page Heading -->
        <div class="d-sm-flex align-items-center justify-content-between mb-2" *ngIf="editedOrder">
          <h4 class="h4 mb-0 text-gray-800">{{ 'transfer_request' | translate}}<i class="fas fa-chevron-right fa-xs  m-3"></i> <span class="text-uppercase">{{editedOrder['reference']}} - {{editedOrder['internalref']}}</span>
            <a href="{{'/order/duplicate/'+editedOrder.category+'/'+editedOrder.id}}" target="_blank" data-toggle="tooltip" data-placement="top" title="{{'duplicate_order' | translate}}"><i class="far fa-copy ml-3"></i></a>
          </h4>
        </div>

        <div class="col-sm-12 col-md-12 pt-8" *ngIf="!orderLoaded">
          <div class="d-flex justify-content-center align-items-center">
            <img style="width: 4vw; height: auto;" src="../../assets/img/load.gif" />
          </div>
        </div>


        <!-- Content Row -->
        <div class="row" *ngIf="editedOrder && orderLoaded">

          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-4">
                    <label class="font-weight-bold"> {{ 'type' | translate }} </label>
                    <p class="shadow-sm rounded p-2 bg-white"> {{editedOrder.category  | translate | uppercase}} </p>
                  </div>
                  <div class="col-md-8">
                    <label class="font-weight-bold"> {{ 'issued_by' | translate }} </label>
                    <p class="shadow-sm rounded p-2 text-uppercase bg-white"> {{editedOrder.createdby}} - {{editedOrder.createddate | date:dateLocale}} </p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <label class="font-weight-bold"> {{ 'order_object' | translate }} </label>
                    <p class="shadow-sm rounded p-2 bg-white"> {{editedOrder.object}} </p>
                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">
                    <label class="font-weight-bold"> {{ 'order_status' | translate }} </label>
                    <p class="alert  shadow-sm rounded p-2 text-uppercase"
                       [ngClass]="{'alert-warning' : editedOrder.status == 'pending',
                                          'alert-danger' : editedOrder.status == 'rejected',
                                          'alert-primary' : editedOrder.status == 'validated',
                                          'alert-info' : editedOrder.status == 'pending_payment',
                                          'alert-success' : editedOrder.status == 'finished' }"

                       role="alert">
                      {{editedOrder.status | translate}}
                    </p>
                  </div>

                </div>
                <div class="row">
                  <div class="col-md-12"  *ngIf="!(editedOrder.status == 'rejected')" >
                    <!-- <label class="font-weight-bold"> {{ 'provider' | translate }} </label>
                    <p class="shadow-sm rounded p-2 bg-white"> {{editedOrder.provider | uppercase}} </p> -->
                    <label class="font-weight-bold"> {{ 'approved_by' | translate }} </label>
                    <p class="shadow-sm rounded p-2 text-uppercase bg-white">

                                      <span class="d-flex justify-content-center align-items-center" *ngIf="!validationsLoaded" >
                                        <img style="width: 4vw; height: auto;" src="../../assets/img/load.gif" />
                                      </span>

                      <span *ngFor="let validation of editedOrder.validations">
                                          <span>* {{validation.employee.firstname}} {{validation.employee.lastname}} ({{validation.employee.level}}) - {{validation.date | date:dateLocale}} <br/>
                                          </span>
                                      </span>
                      <span *ngIf="validationsLoaded && !(editedOrder.validations?.length > 0)" class="text-danger" >{{'no_validation' | translate}}</span>

                    </p>
                  </div>

                  <div class="col-md-12" *ngIf="editedOrder.status == 'rejected'  && editedOrder.comment" >
                    <label class="font-weight-bold"> {{ 'rejected_by' | translate }} </label>
                    <p class="shadow-sm rounded p-2 text-uppercase bg-white">
                      <label class="font-weight-bold text-uppercase">{{editedOrder.comment.name}} ({{editedOrder.comment.date | date:dateLocale}})</label> <br>
                      <span class="text-danger "> [{{editedOrder.comment.comment}}] </span>
                    </p>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12">
                <div *ngIf="getInputErrorStatus()" class="alert alert-danger col-md-12 center mt-5 p-2 mb-1 col-form-label" role="alert">

                  <p *ngFor="let error of errors">
                    <span class="font-weight-bold mr-3" *ngIf="error.line > 0"> {{'line' | translate}} {{error.line}} -  </span>
                    <span>{{error.name | translate}}</span>
                  </p>

                </div>

                <div *ngIf="getFileErrorStatus()" class="alert alert-danger col-md-12 center mt-5 p-2 mb-1 col-form-label" role="alert">

                  <div *ngFor="let error of errorList | keyvalue">
                    <span class="font-weight-bold mr-3" *ngIf="error.value.length > 0">  {{'line' | translate}}  ({{error.value}}) - {{ error.key | translate}} </span>
                  </div>

                </div>
              </div>
              <div class="col-md-9">
                <div class="card shadow mb-4">
                  <!-- Card Header - Dropdown -->
                  <div class="card-header bg-light text-white py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 *ngIf="editedOrder.amount > 0" class="m-0 font-weight-bold text-primary">
                      {{'total' | translate}}: XAF {{editedOrder.amount | number}}
                    </h6> <span></span>
                    <div class="d-flex justify-content-end">
                      <a href="{{'http://'+editedOrder.orderProof}}" target="_blank" class="btn btn-outline-secondary mr-3" *ngIf="editedOrder.orderProof">
                        <i class="fas fa-file-pdf mr-2 text-info"></i> {{ 'download_voucher' | translate}} (.pdf)
                      </a>
                      <a href="{{'http://'+editedOrder.orderSignature}}" target="_blank" class="btn btn-outline-secondary" *ngIf="editedOrder.orderSignature">
                        <i class="fas fa-file-csv mr-2 text-success"></i> {{ 'download_signature' | translate}} (.csv)
                      </a>
                    </div>
                  </div>
                  <!-- Card Body -->
                  <div class="card-body">
                    <h4 class="mb-3">{{'transfer_list' | translate}}</h4>
                    <div class="col-sm-12 col-md-12 pt-8" *ngIf="!loaded_transfers &&  orderLoaded">
                      <div class="d-flex justify-content-center align-items-center">
                        <img style="width: 4vw; height: auto;" src="../../assets/img/load.gif" />
                      </div>
                    </div>
                    <div class="table-responsive" *ngIf="loaded_transfers" >
                      <table class="table table-bordered table-hover table-striped table-sm" id="editTransferTable" width="100%" cellspacing="0">
                        <thead>
                        <tr>
                          <th></th>
                          <th style="width: 15%">{{'ref_nbr' | translate}}</th>
                          <th *ngIf="(editedOrder.provider === 'eneoprepay')">{{'meter_id' | translate}}</th>
                          <th *ngIf="(editedOrder.provider === 'canal')">{{'card_number' | translate}}</th>
                          <th style="width: 15%" *ngIf="(editedOrder.provider !== 'canal')">{{'amount' | translate}}</th>
                          <th *ngIf="(editedOrder.provider === 'canal')">{{'bundle' | translate}}</th>
                          <th *ngIf="!(editedOrder.provider === 'canal' || editedOrder.provider === 'eneoprepay' || editedOrder.type === 'airtime'  || editedOrder.type === 'internet')">{{'payment_method' | translate}}</th>
                          <th *ngIf="editedOrder.type === 'airtime'">{{'network_operator' | translate}}</th>
                          <th *ngIf="!(editedOrder.category === 'airtime')">{{'account_number' | translate}}</th>
                          <th *ngIf="(editedOrder.category === 'airtime')">{{'phone' | translate}}</th>
                          <th>{{'client_name' | translate}}</th>
                          <th *ngIf="(editedOrder.status === 'finished' || editedOrder.status === 'pending_payment' )">{{'status' | translate}}</th>
                          <!-- <th>Document</th>
                          <th style="width: 15%" *ngIf="(!editedOrder.orderSignature || editedOrder.transfers.length === 0) && user.isMaker()"></th> -->
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let transfer of editedOrder.transfers | paginate: config; let i = index">
                          <td>{{i+1}}</td>

                          <td class="text-uppercase" *ngIf="editedRow !== i">
                            {{transfer.internalref}}
                          </td>
                          <!-- Edited Row begin-->
                          <td *ngIf="editedRow == i">
                            <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="editedTransfer.internalref" class="form-control" placeholder=" {{ 'reference_number' | translate}} ">
                          </td>
                          <!---->

                          <td *ngIf="(editedOrder.provider === 'eneoprepay' && editedRow !== i)">{{transfer.meterId}}</td>
                          <!-- Edited Row begin-->
                          <td *ngIf="(editedOrder.provider === 'eneoprepay' && editedRow == i)"> <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="editedTransfer.meterId" class="form-control" placeholder=" {{ 'meter_id' | translate}} "></td>
                          <!---->

                          <td *ngIf="(editedOrder.provider === 'canal' && editedRow !== i)">{{transfer.cardNumber | number}}</td>
                          <!-- Edited Row begin-->
                          <td *ngIf="(editedOrder.provider === 'canal' && editedRow == i)"> <input type="number" [ngModelOptions]="{standalone: true}" [(ngModel)]="editedTransfer.cardNumber" class="form-control numberInput" placeholder=" {{ 'card_number' | translate}} "></td>
                          <!---->

                          <td *ngIf="(editedOrder.provider !== 'canal' && editedRow !== i)">XAF {{transfer.amount | number}}</td>
                          <!-- Edited Row begin-->
                          <td *ngIf="(editedOrder.provider !== 'canal' && editedRow == i)"> <input type="number" min="100" [ngModelOptions]="{standalone: true}" [(ngModel)]="editedTransfer.amount" class="form-control numberInput" placeholder=" {{ 'amount' | translate}} "></td>
                          <!---->

                          <td *ngIf="(editedOrder.provider === 'canal' && editedRow !== i)" class="text-uppercase">{{transfer.bundleTitle}}</td>
                          <!-- Edited Row begin-->
                          <td *ngIf="(editedOrder.provider === 'canal' && editedRow == i)">
                            <select [ngModelOptions]="{standalone: true}" [(ngModel)]="editedTransfer.bundleId" class="form-control">
                              <option [defaultSelected]="true" value="-1"> {{ 'choose_bundle' | translate}} </option>
                              <option *ngFor="let bundle of bundleList; let i = index" [value]="i"> {{bundle.title}} </option>
                            </select>
                          </td>
                          <!---->

                          <td *ngIf="!(editedOrder.provider === 'canal' || editedOrder.provider === 'eneoprepay' || editedOrder.type === 'airtime' || editedOrder.type === 'internet') && editedRow !== i" class="text-uppercase">{{transfer.paymentMethodTitle}}</td>
                          <!-- Edited Row begin-->
                          <td *ngIf="!(editedOrder.provider === 'canal' || editedOrder.provider === 'eneoprepay' || editedOrder.type === 'airtime' || editedOrder.type === 'internet') && editedRow == i">
                            <select [ngModelOptions]="{standalone: true}" [(ngModel)]="editedTransfer.paymentMethodId" class="form-control">
                              <option [defaultSelected]="true" value ="-1"> {{ 'payment_method' | translate}} </option>
                              <option *ngFor="let method of paymentMethodList; let i = index" [value]="i"> {{method.title}} </option>
                            </select>
                          </td>
                          <!---->

                          <td *ngIf="editedOrder.type === 'airtime' && editedRow !== i" class="text-uppercase">{{transfer.networkOperator }}</td>
                          <!-- Edited Row begin-->
                          <td *ngIf="editedOrder.type === 'airtime' && editedRow == i">
                            <select [ngModelOptions]="{standalone: true}" [(ngModel)]="editedTransfer.networkOperatorId" class="form-control">
                              <option [defaultSelected]="true" value ="-1"> {{ 'network_operator' | translate}} </option>
                              <option *ngFor="let network of networkOperators; let i = index" [value]="i"> {{network.title}} </option>
                            </select>
                          </td>
                          <!---->

                          <td *ngIf="editedRow !== i">{{transfer.phone}}</td>
                          <!-- Edited Row begin-->
                          <td *ngIf="editedRow == i"> <input type="number" max="999999999" min="100000000" class="form-control numberInput" [ngModelOptions]="{standalone: true}" [(ngModel)]="editedTransfer.phone" placeholder=" {{ 'account_number' | translate}} "></td>
                          <!---->

                          <td *ngIf="editedRow !== i" class="text-uppercase text-right" style = "font-size: 0.7em !important;">{{transfer.name}}</td>
                          <!-- Edited Row begin-->
                          <td *ngIf="editedRow == i"><input type="text" class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="editedTransfer.name" placeholder=" {{ 'client_name' | translate}} "></td>
                          <!---->

                          <td *ngIf="editedOrder.status == 'pending_payment' || editedOrder.status == 'finished'">
                            <i *ngIf="transfer.status == 'pending'" class="fas fa-spinner text-info"></i>
                            <i *ngIf="transfer.status == 'failed'" class="fas fa-times-circle text-danger"></i>
                            <i *ngIf="transfer.status == 'success'" class="fas fa-check-circle text-success"></i>
                          </td>


                          <!-- Edited Row begin
                          <td *ngIf="editedRow == i">
                              <label class="btn btn-primary shadow-sm" *ngIf="!editedRow.document" >
                                  <i class="fe fe-file-plus text-white mr-2"></i> {{'add_doc'|translate}}
                                  <input type="file" accept="image/png, image/jpeg, image.jpg, .pdf"  hidden  (change)="addFile($event, i)"/>
                              </label>

                              <button  *ngIf="editedRow.document"  class="btn btn-danger text-white" (click) ="delFile(i)">
                                  <i class="fe fe-file-minus mr-2"   ></i> {{'delete_doc'|translate}}
                              </button>
                          </td>

                          <td *ngIf="editedRow == -1 && editedRow !== i && !editedOrder.orderSignature && user.isMaker()">
                              <a (click)="editTransfer(i)" class=" d-inline-block mr-3" role="button">
                                  <i class="far text-black-50 fa-edit"></i>
                              </a>
                          </td> -->

                          <!-- Edited Row begin-->
                          <!-- <td *ngIf="editedRow == i && user.isMaker()">
                              <button (click)="saveModif()" class="btn btn-success text-center text-black-50 mr-3" role="button">
                                  <i class="fas text-black-50 fa-check"></i>
                              </button>

                              <button (click)="cancelModif()" class="btn btn-secondary text-center text-black-50" role="button">
                                  <i class="far text-black-50 fa-times-circle"></i>
                              </button>
                          </td> -->
                          <!---->

                          <!-- Edited Row begin-->
                          <!-- <td *ngIf="editedOrder.orderSignature">

                              <button  *ngIf="transfer.document"  class="btn btn-danger text-white" (click) = "delFile(i)">
                                  <i class="fe fe-file-minus mr-2"></i> {{'delete_doc'|translate}}
                              </button>
                          </td> -->
                          <!---->
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="d-flex justify-content-end">
                    <span>Pages</span>
                    <pagination-controls

                      [responsive]="responsive"
                      [previousLabel]="labels.previousLabel"
                      [nextLabel]="labels.nextLabel"

                      *ngIf="config" (pageChange)="pageChange($event)" aria-label="Page navigation example" class="my-pagination">
                    </pagination-controls>
                  </div>
                </div>
              </div>

              <div class="col-md-3" *ngIf="editedOrder.status == 'pending_payment' || editedOrder.status == 'finished'" >
                <div class="card shadow mb-4">
                  <!-- Card Header - Dropdown -->
                  <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 class="m-0 font-weight-bold"> <i class="fas fa-tasks fa-sm fa-fw mr-2"></i>Monitoring</h6>
                  </div>
                  <!-- Card Body -->
                  <div class="card-body">
                    <table>
                      <tr class="text-success text-xs font-weight-bold">
                        <td>{{editedOrder.success}}/{{editedOrder.getTransferNbr()}}</td>
                        <td  style="font-size: 1em !important; text-align: left !important;" >{{'processed'| translate}}</td>
                      </tr>
                      <tr class="text-danger text-xs font-weight-bold ">
                        <td>{{editedOrder.failed}}/{{editedOrder.getTransferNbr()}}</td>
                        <td  style="font-size: 1em !important; text-align: left !important;" >{{'failed'| translate}}</td>
                      </tr>
                      <tr class="text-warning text-xs font-weight-bold">
                        <td>{{editedOrder.pending}}/{{editedOrder.getTransferNbr()}}</td>
                        <td  style="font-size: 1em !important; text-align: left !important;" >{{'processing'| translate}}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="d-sm-flex align-items-center justify-content-between mb-4 p-3" *ngIf="editedOrder && orderLoaded">
        <div class="pt-6 d-inline">
          <a class="btn btn-secondary text-white" href="/dashboard">
            <i class="far fa-times-circle"></i> {{ 'quit' | translate}}
          </a>
          <!-- <button class="btn btn-primary ml-4" *ngIf="user.isMaker()" data-toggle="modal" data-target="#savOrderModal">
              <i class="fas fa-save mr-2"></i> {{ 'save_modif' | translate}}
          </button> -->

          <button class="btn btn-primary ml-4" data-toggle="modal" *ngIf="user.isChecker() && canValidate() && (editedOrder.status=='pending')" data-target="#approveOrderModal">
            <i class="far fa-thumbs-up mr-2"></i> {{ 'approve_request' | translate}}
          </button>

          <button class="btn btn-warning ml-4 text-dark" data-toggle="modal" *ngIf="(editedOrder.status == 'pending') && canValidate()"  data-target="#rejectOrderModal">
            <i class="far fa-thumbs-down mr-2"></i> {{ 'reject_request' | translate}}
          </button>

          <button class="btn btn-warning ml-4 text-dark" (click)="createFromThis()"
                  *ngIf="canCreate()">
            <i class="far fa-thumbs-down mr-2"></i> {{ 'create_from_this' | translate}}
          </button>
        </div>

        <div class="pt-6 d-inline">
          <!-- <button class="btn btn-danger ml-4" *ngIf="user.isMaker()" data-toggle="modal" data-target="#deleteOrderModal">
              <i class="fas fa-trash mr-2"></i> {{ 'delete_request' | translate}}
          </button> -->
        </div>
      </div>
    </div>
    <footer class="sticky-footer bg-white">
      <app-footer></app-footer>
    </footer>
  </div>
</div>



<!--Modal complete order-->
<div class="modal" *ngIf="loaded_transfers" id="savOrderModal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="savOrderModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" *ngIf="editedOrder">
      <div class="modal-header" [ngClass]="{'bg-primary': !isCreationOk && !errorOccurs, 'bg-success': isCreationOk, 'bg-danger': errorOccurs}">
        <h5 class="modal-title text-white" id="savOrderModalLabel">
          <span *ngIf="!isCreationOk  && !errorOccurs"><i class="fas fa-save fa-sm fa-fw mr-2"></i>{{'save_modif' | translate}} ?</span>
          <span *ngIf="isCreationOk"><i class="fas fa-clipboard-check fa-sm fa-fw mr-2"></i>{{'successfully_created' | translate}}</span>
          <span *ngIf="errorOccurs"><i class="fas fa-times-circle fa-sm fa-fw  mr-2"></i>{{'error_occured' | translate}}</span>
        </h5>
      </div>
      <div class="modal-body">
        <p class="text-uppercase"> {{'transfer_request' | translate}} : <span class="text-primary">{{editedOrder.internalref || editedOrder['internal_ref_number']}} </span></p>
        <p> {{'total_amount' | translate}} : <span class="text-primary">XAF {{editedOrder.amount | number}}</span> ( {{editedOrder.transfertNumber}} {{'transfer' | translate}}(s) )</p>
        <p class="text-uppercase"> {{'type' | translate}} :
          <span class="text-primary text-uppercase">{{editedOrder.category | translate}}</span>
          <span *ngIf="editedOrder.provider"> | {{editedOrder.provider}}</span>
        </p>
        <div class="row">
          <div class="col-sm-12 col-md-12 pt-4">
            <div class="d-flex justify-content-center align-items-center" *ngIf="showLoader">
              <img style="width: 4vw; height: auto;" src="../../assets/img/load.gif" />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" *ngIf="!isCreationOk" type="button" data-dismiss="modal">{{'no' | translate}}</button>
        <button class="btn btn-primary" *ngIf="!isCreationOk" [disabled]="showLoader" role="button">{{'yes' | translate}} </button>
        <a class="btn btn-info" *ngIf="isCreationOk" href="dashboard" role="button">{{'close' | translate}}</a>
      </div>
    </div>
  </div>
</div>


<!--Modal complete order-->
<div class="modal" *ngIf="loaded_transfers" id="deleteOrderModal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="deleteOrderModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" *ngIf="editedOrder">
      <div class="modal-header" [ngClass]="{'bg-primary': !isCreationOk && !errorOccurs, 'bg-success': isCreationOk, 'bg-danger': errorOccurs}">
        <h5 class="modal-title text-white" id="deleteOrderModalLabel">
          <span *ngIf="!isCreationOk  && !errorOccurs"><i class="fas fa-trash  fa-sm fa-fw mr-2"></i>{{'delete_request' | translate}} ?</span>
          <span *ngIf="isCreationOk"><i class="fas fa-clipboard-check fa-sm fa-fw mr-2"></i>{{'successfully_created' | translate}}</span>
          <span *ngIf="errorOccurs"><i class="fas fa-times-circle fa-sm fa-fw  mr-2"></i>{{'error_occured' | translate}}</span>
        </h5>
      </div>
      <div class="modal-body">
        <p class="text-uppercase"> {{'transfer_request' | translate}} : <span class="text-primary">{{editedOrder.internalref || editedOrder['internal_ref_number']}} </span></p>
        <p> {{'total_amount' | translate}} : <span class="text-primary">XAF {{editedOrder.amount | number}}</span> ( {{editedOrder.transfertNumber}} {{'transfer' | translate}}(s) )</p>
        <p class="text-uppercase"> {{'type' | translate}} :
          <span class="text-primary text-uppercase">{{editedOrder.category | translate}}</span>
          <span *ngIf="editedOrder.provider"> | {{editedOrder.provider}}</span>
        </p>
        <div class="row">
          <div class="col-sm-12 col-md-12 pt-4">
            <div class="d-flex justify-content-center align-items-center" *ngIf="showLoader">
              <img style="width: 4vw; height: auto;" src="../../assets/img/load.gif" />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" *ngIf="!isCreationOk" type="button" data-dismiss="modal">{{'no' | translate}}</button>
        <button class="btn btn-primary" *ngIf="!isCreationOk" [disabled]="showLoader" role="button" (click)="deleteOrder()">{{'yes' | translate}} </button>
        <a class="btn btn-info" *ngIf="isCreationOk" href="dashboard" role="button">{{'close' | translate}}</a>
      </div>
    </div>
  </div>
</div>

<!--Previez transfers document-->
<div id="preview">
  <div class="bg-primary text-right p-2">
    <span class="fe fe-x text-white"  id="closePreview"  (click) = "togglePreview(false)"  ></span>
  </div>
  <ngx-extended-pdf-viewer
    *ngIf="pdfSrc"
    [base64Src]="pdfSrc"
    useBrowserLocale="true"
    [language] = "lang"
    height="100%">
  </ngx-extended-pdf-viewer>

  <ngx-image-viewer
    *ngIf="imgSrc"
    [src]="imgSrc"
    [config]="{customBtns:[{name: 'link', icon: 'fa fa-paperclip'}]}">
  </ngx-image-viewer>
</div>
<!--End of preview-->


<!--Modal complete order-->
<div class="modal" id="rejectOrderModal" *ngIf="loaded_transfers" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="rejectOrderModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" *ngIf="editedOrder">
      <div class="modal-header" [ngClass]="{'bg-primary': !isCreationOk && !errorOccurs, 'bg-success': isCreationOk, 'bg-danger': errorOccurs}">
        <h5 class="modal-title text-white" id="rejectOrderModalLabel">
          <span *ngIf="!isCreationOk  && !errorOccurs"><i class="far fa-thumbs-down  fa-sm fa-fw mr-2"></i>{{'reject_request' | translate}} ?</span>
          <span *ngIf="isCreationOk"><i class="fas fa-clipboard-check fa-sm fa-fw mr-2"></i>{{'successfully_created' | translate}}</span>
          <span *ngIf="errorOccurs"><i class="fas fa-times-circle fa-sm fa-fw  mr-2"></i>{{'error_occured' | translate}}</span>
        </h5>
      </div>
      <div class="modal-body">
        <p class="text-uppercase"> {{'transfer_request' | translate}} : <span class="text-primary">{{editedOrder.internalref}} </span></p>
        <p> {{'total_amount' | translate}} : <span class="text-primary">XAF {{editedOrder.amount | number}}</span> ( {{editedOrder.transfertNumber}} {{'transfer' | translate}}(s) )</p>
        <p class="text-uppercase"> {{'type' | translate}} :
          <span class="text-primary text-uppercase">{{editedOrder.category | translate}}</span>
          <span *ngIf="editedOrder.provider"> | {{editedOrder.provider}}</span>
          <span class="alert alert-warning p-4" *ngIf="errorOccurs" >{{errorMessage | translate}}</span>
        </p>
        <span>{{'leave_comment' | translate}} </span> <span class="ml-3 text-danger" *ngIf="tooShortText">{{'too_short_text' | translate}} </span>
        <textarea [ngModelOptions]='{standAlone: true}' [(ngModel)]="comments" style="width: 100%;" rows="6">

                </textarea>
        <div class="row">
          <div class="col-sm-12 col-md-12 pt-4">
            <div class="d-flex justify-content-center align-items-center" *ngIf="showLoader">
              <img style="width: 4vw; height: auto;" src="../../assets/img/load.gif" />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" *ngIf="!isCreationOk" type="button" data-dismiss="modal">{{'no' | translate}}</button>
        <button class="btn btn-primary" *ngIf="!isCreationOk" [disabled]="showLoader" role="button" (click)="rejectOrder()">{{'yes' | translate}} </button>
        <a class="btn btn-info" *ngIf="isCreationOk" href="dashboard" role="button">{{'close' | translate}}</a>
      </div>
    </div>
  </div>
</div>

<!--Modal complete order-->
<div class="modal" *ngIf="loaded_transfers" id="approveOrderModal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="approveOrderModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" *ngIf="editedOrder">
      <div class="modal-header" [ngClass]="{'bg-primary': !isCreationOk && !errorOccurs, 'bg-success': isCreationOk, 'bg-danger': errorOccurs}">
        <h5 class="modal-title text-white" id="approveOrderModalLabel">
          <span *ngIf="!isCreationOk  && !errorOccurs"><i class="far fa-thumbs-up  fa-sm fa-fw mr-2"></i>{{'approve_request' | translate}} ?</span>
          <span *ngIf="isCreationOk"><i class="fas fa-clipboard-check fa-sm fa-fw mr-2"></i>{{'successfully_created' | translate}}</span>
          <span *ngIf="errorOccurs"><i class="fas fa-times-circle fa-sm fa-fw  mr-2"></i>{{'error_occured' | translate}}</span>
        </h5>
      </div>
      <div class="modal-body">
        <p class="text-uppercase"> {{'transfer_request' | translate}} : <span class="text-primary">{{editedOrder.internalref || editedOrder['internal_ref_number']}} </span></p>
        <p> {{'total_amount' | translate}} : <span class="text-primary">XAF {{editedOrder.amount | number}}</span> ( {{editedOrder.transfertNumber}} {{'transfer' | translate}}(s) )</p>
        <p class="text-uppercase"> {{'type' | translate}} :
          <span class="text-primary text-uppercase">{{editedOrder.category | translate}}</span>
          <span *ngIf="editedOrder.provider"> | {{editedOrder.provider}}</span>
          <span class="alert alert-warning p-4" *ngIf="errorOccurs" >{{errorMessage | translate}}</span>
        </p>
        <div class="row">
          <div class="col-sm-12 col-md-12 pt-4">
            <div class="d-flex justify-content-center align-items-center" *ngIf="showLoader">
              <img style="width: 4vw; height: auto;" src="../../assets/img/load.gif" />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" *ngIf="!isCreationOk" type="button" data-dismiss="modal">{{'no' | translate}}</button>
        <button class="btn btn-primary" *ngIf="!isCreationOk" [disabled]="showLoader" role="button" (click)="validateOrder()">{{'yes' | translate}} </button>
        <a class="btn btn-info" *ngIf="isCreationOk" href="dashboard" role="button">{{'close' | translate}}</a>
      </div>
    </div>
  </div>
</div>
