import { Component, OnInit } from '@angular/core';
import { WindowService } from '../window.service';
import { PhoneNumber } from 'src/classes/PhoneNumber';
import {Router} from '@angular/router';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import firebase from 'firebase/app';
import {AngularFireAuth} from '@angular/fire/auth';
import { AuthService } from '../auth.service';
import { User } from 'src/classes/User';
import { FormControl, FormGroup, Validators,ReactiveFormsModule } from '@angular/forms';
import {environment} from '../../environments/environment';
import { StoreService } from '../store.service';
declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  windowRef: any;
  phoneNumber : PhoneNumber;
  verificatioCode: string;
  captchaResponse = '';
  authCodeResponse = '';
  captchaIssue = false;
  recaptchaContent: any;
  user: User;
  showMask = false;
  unauthorize = false;
  loading = false;
  showOTPinput = false;
  loginOTP = '';
  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.Cameroon, CountryISO.UnitedStates];
  loginForm : FormGroup;
  verificationCode: string;
  showOTPForm = false;
  isFirebase = false;
  isSMSAuth = false;
  phone : string;
  incorrectOTP = false;
  updatingPin = false;
  passwordForm : FormGroup;
  passwordError = false;
  isFirstLogin = false;
  message : string;
  token : string;
  general_failure : string;

  constructor(private win: WindowService, private router: Router, private store: StoreService,
    private auth : AuthService, public fireAuth : AngularFireAuth) { }

  ngOnInit() {
    $('body').addClass('bg-login');

    if(this.store.getIsLoggedIn()){
      this.router.navigateByUrl('dashboard');
    }

    this.windowRef = this.win.windowRef;

    this.loginForm = new FormGroup({
      email: new FormControl("", [Validators.required, Validators.email]),
      password: new FormControl("", [Validators.required, Validators.minLength(6)])
    });

    this.passwordForm = new FormGroup({
      newPassword: new FormControl("", [Validators.required, Validators.minLength(6)]),
      passwordConfirm : new FormControl("", [Validators.required, Validators.minLength(6)])
    });

  }

  cancelAll() {
    this.loginOTP = '';
    this.showOTPinput = false;
    this.loading = false;
    this.isFirebase = false;
    this.isSMSAuth = false;
    delete this.phoneNumber;
    this.showOTPForm = false;
    this.loginOTP = "";
    delete this.windowRef;
    this.windowRef = this.win.windowRef;
    delete this.user;
    this.isFirstLogin = false;
    delete this.token;
    delete this.message;

  }


  /*
    first login
    email
    password
  */
  onLogin(newPassword){
    delete this.general_failure;
    delete this.incorrectOTP;
    delete this.phone;
    delete this.phoneNumber;
    delete this.isFirstLogin;

    this.loading = true;
    let data = {
      login : this.loginForm.value.email,
      pin : newPassword ? newPassword : this.loginForm.value.password,
      orgplatform : environment.orgplatform
    }
    this.auth.signIn(data).subscribe((data: any) => {
      this.loading = false;
      if(data.code === 200) {
        this.showMask = false;
        this.user = new User(data.result.employee);
        if(this.user.organisation.authMethod.slug === "email-firebase-otp"){
          this.isFirebase = true;
          this.showOTPForm = true;
          this.phone = this.phone;
          this.phoneNumber = new PhoneNumber(this.user.phone);
          setTimeout(() =>{
            this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container',{
              'size': 'invisible'
            });
            // this.windowRef.recaptchaVerifier.render();
            this.sendLoginCode();
          }, 100)
          return;
        }
        data.result.employee.token = data.result.token;
        this.user = new User(data.result.employee);
        this.store.saveUserData(this.user);
        this.goToApp();

      }else {
        if(data.code == 201){

          this.isFirstLogin = true;

        } else {

          if(data.code == 202) {
            this.isSMSAuth = true;
            this.showOTPForm = true;
            this.message = data.message;
            this.token = data.result.token;
          } else {
            this.general_failure = data.message;
          }
        }
      }
    },
    (error) => {
      if(error){
        console.log(error);
        this.loading = false;
        this.general_failure = "error_occured";
      }
    });
  }


  /*
    change password
    email
    oldpassword
    new password
  */
  onPasswordChange(){
    delete this.general_failure;
    this.passwordError = false;

    if(!(this.passwordForm.value.newPassword == this.passwordForm.value.passwordConfirm)){
      this.passwordError = true;
      return;
    }

    this.loading = true;
    let data_login = {
      pin : this.passwordForm.value.newPassword,
      oldpin : this.loginForm.value.password,
      login : this.loginForm.value.email
    }
    this.auth.changePassword(data_login).subscribe((data : any) => {
      this.loading = false;
      if(data.code === 200) {
        this.showMask = false;
        this.onLogin(data_login.pin);
      } else {
        this.general_failure = data.message;
      }
    },
    (error) => {
      if(error){
        console.log(error);
        this.loading = false;
        this.general_failure = "error_occured";
      }
    })
  }

  /*
    Verify OTP
    code
    token
  */
  checkOTP(){
    this.passwordError = false;
    delete this.general_failure;
    delete this.incorrectOTP;

    if(!(this.passwordForm.value.newPassword == this.passwordForm.value.passwordConfirm)){
      this.passwordError = true;
      return;
    }

    this.loading = true;
    let data = {
      code : this.loginOTP,
      token : this.token
    }
    this.auth.VerifyCode(data).subscribe((data : any) => {
      this.loading = false;
      if(data.code === 200) {
        this.showMask = false;
        data.result.employee.token = data.result.token;
        this.user = new User(data.result.employee);
        this.store.saveUserData(this.user);
        this.goToApp();
      }else {
        this.incorrectOTP = true;
      }
    },
    (error) => {
      if(error){
        console.log(error);
        this.loading = false;
        this.general_failure = "error_occured";
      }
    })
  }

  goToApp(){
    if(this.user.isAdmin()){
      this.router.navigateByUrl('/admin/user/manager');
    }else {
      this.router.navigateByUrl('/dashboard');
    }
  }


  sendLoginCode() {
    const appVerifier = this.windowRef.recaptchaVerifier;
    const num = this.phoneNumber.e164;
    this.fireAuth.signInWithPhoneNumber(num, appVerifier)
            .then(result => {
                this.windowRef.confirmationResult = result;
            })
            .catch( error => console.log(error) );
  }

  verifyLoginCode() {
    this.incorrectOTP = false;
    this.windowRef.confirmationResult
      .confirm(this.loginOTP)
      .then(result => {
        var user = result.user;
        if(user) {
          this.store.saveUserData(this.user);
          this.showMask =  true;
          this.goToApp();
        }else {
          this.incorrectOTP = true;
        }

      })
  }


  // loadInputPhoneScripts() {
  //   // tslint:disable-next-line: curly
  //   if (document.getElementById('testScript')) document.getElementById('testScript').remove();
  //   const testScript = document.createElement('script');
  //   testScript.setAttribute('id', 'testScript');
  //   testScript.setAttribute('src', 'assets/js/inputPhone.js');
  //   document.body.prepend(testScript);
  // }

}
