import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private httpService: HttpService) {  }


  signIn(data : {login : string, pin : string, orgplatform : string}) {
    let encoded = JSON.stringify(data);
    return this.httpService.post('biz/login/', JSON.stringify(data));
  }

  getUsers(){
    return this.httpService.post('biz/users/', null);
  }

  VerifyCode(data: {token : string, code : string}){
    return this.httpService.post('biz/otp/verify/', JSON.stringify(data), data.token);
  }

  getBalance(){
    return this.httpService.get('biz/organization/balance/');
  }

  getDirectors(){
    return this.httpService.get('biz/organisation/directors/');
  }

  getEmployees(page, number){
    return this.httpService.get('biz/organisation/employees/?page='+page+'&size='+number);
  }

  setUserStatus(user, phone_by, status){
    return this.httpService.post('biz/user/active/', JSON.stringify({phone_by, user, status }));
  }

  changePassword(data : {login : string, pin : string, oldpin : string}){
    return this.httpService.post('biz/login/changepassword/', JSON.stringify(data));
  }
}
