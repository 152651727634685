<!-- Page Wrapper -->
<div id="wrapper">
    <!-- Sidebar -->
    <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled" id="accordionSidebar">
        <app-sidebar></app-sidebar>
    </ul>
    <!-- Content Wrapper -->
    <div id="content-wrapper" class="d-flex flex-column">
        <!-- Main Content -->
        <div id="content">
            <!-- Topbar -->
            <app-header></app-header>
            <div class="container-fluid">
                <!-- Page Heading -->
                <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h4 class="h4 mb-0 text-gray-800">{{ 'company_profile' | translate}}</h4>
                </div>
                <!-- Content Row -->
                <div class="row">
                    <div class="col-lg-6">
                        <!-- Card begin basic infs -->
                        <div class="card mb-4">
                            <div class="card-header">
                                {{'basic_info' | translate}}
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <span class="d-inline-block col-md-4 col-sm-12 text-black-300">{{'company_name' | translate}} : </span>
                                    <span class="d-inline-block col-md-8 col-sm-12  text-black-300"> {{company.name}}</span>
                                </div>
                                <div class="row">
                                    <span class="d-inline-block col-md-4 col-sm-12  text-black-300">{{'email' | translate}} : </span>
                                    <span class="d-inline-block col-md-8 col-sm-12  text-black-300">{{company.email}}</span>
                                </div>
                                <div class="row">
                                    <span class="d-inline-block col-md-4 col-sm-12  text-black-300">{{'phone' | translate}} : </span>
                                    <span class="d-inline-block col-md-8 col-sm-12  text-black-300">{{company.fixedphone}} <span *ngIf="company.mobilephone">| {{company.mobilephone}}</span> <span *ngIf="company.mobilephone">| {{company.mobilphone}}</span>                                    </span>
                                </div>
                                <div class="row">
                                    <span class="d-inline-block col-md-4 col-sm-12  text-black-300">{{'account_number' | translate}} : </span>
                                    <span class="d-inline-block col-md-8 col-sm-12  text-black-300">{{company.accountNumber}}</span>
                                </div>
                                <div class="row">
                                    <span class="d-inline-block col-md-4 col-sm-12  text-black-300">{{'address' | translate}} : </span>
                                    <span class="d-inline-block col-md-8 col-sm-12  text-black-300">{{company.address}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-sm-flex align-items-center justify-content-between mb-4">
                  <h4 class="h4 mb-0 text-gray-800">Managers</h4>
                </div>

                <div *ngIf="!loading_directors && !(directors?.length > 0)" class="d-flex justify-content-center">
                  <h3>{{'no_manager_found' | translate}}</h3>
                </div>
                <div class="d-flex justify-content-center" *ngIf="loading_directors">
                  <img style="width: 4vw; height: auto;" src="../../assets/img/load.gif" />
                </div>

                <div class="row mb-4">
                  <div class="col-12 col-sm-6 col-md-4" *ngFor="let e of directors">
                      <!-- Card begin basic infs -->
                      <div class="card mb-2" >
                          <div class="card-header py-1">
                              {{e.firstName +" "+ e.lastName}}
                          </div>
                          <div class="card-body"  >
                              <div class="row">
                                  <span class="d-inline-block col-md-4 col-sm-12 text-black-300">Roles :</span>
                                  <span class="col-md4 col sm-12">
                                    <span *ngIf="e.maker" class="badge badge-primary ml-1 mr-2">Maker</span>
                                    <span *ngIf="e.checker" class="badge badge-danger  mr-2">Checker {{e.level}}</span>
                                    <span *ngIf="e.admin" class="badge badge-success  mr-2">Admin</span>
                                  </span>
                              </div>
                              <div class="row">
                                  <span class="d-inline-block col-md-4 col-sm-12  text-black-300">{{'email' | translate}} : </span>
                                  <span class="d-inline-block col-md-8 col-sm-12  text-black-300">{{e.email}}</span>
                              </div>
                              <div class="row">
                                  <span class="d-inline-block col-md-4 col-sm-12  text-black-300">{{'phone' | translate}} : </span>
                                  <span class="d-inline-block col-md-8 col-sm-12  text-black-300">{{e.phone}}</span>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>


                <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h4 class="h4 mb-0 text-gray-800">{{ 'team_member' | translate}}</h4>
                </div>
                <div *ngIf="!loading_employees && !(employees?.length > 0)" class="d-flex justify-content-center">
                  <h3>{{'no_employee_found' | translate}}</h3>
                </div>
                <div class="d-flex justify-content-center" *ngIf="loading_employees">
                  <img style="width: 4vw; height: auto;" src="../../assets/img/load.gif" />
                </div>

                <div class="row">
                  <div class="col-12 col-sm-6 col-md-4" *ngFor="let e of employees">
                      <!-- Card begin basic infs -->
                      <div class="card mb-2" >
                          <div class="card-header py-1">
                              {{e.firstName +" "+ e.lastName}}
                          </div>
                          <div class="card-body"  >
                              <div class="row">
                                  <span class="d-inline-block col-md-4 col-sm-12 text-black-300">Roles :</span>
                                  <span class="col-md4 col sm-12">
                                    <span *ngIf="e.maker" class="badge badge-primary ml-1 mr-2">Maker</span>
                                    <span *ngIf="e.checker" class="badge badge-danger  mr-2">Checker {{e.level}}</span>
                                    <span *ngIf="e.admin" class="badge badge-success  mr-2">Admin</span>
                                  </span>
                              </div>
                              <div class="row">
                                  <span class="d-inline-block col-md-4 col-sm-12  text-black-300">{{'email' | translate}} : </span>
                                  <span class="d-inline-block col-md-8 col-sm-12  text-black-300">{{e.email}}</span>
                              </div>
                              <div class="row">
                                  <span class="d-inline-block col-md-4 col-sm-12  text-black-300">{{'phone' | translate}} : </span>
                                  <span class="d-inline-block col-md-8 col-sm-12  text-black-300">{{e.phone}}</span>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>

            </div>
        </div>
        <footer class="sticky-footer bg-white">
            <app-footer></app-footer>
        </footer>
    </div>

</div>
