// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  orgplatform  : "AFB160744",
  apikey : "96e79218965eb72c92a549dd5a330112",
  apiuser : "670b14728ad9902aecba32e22fa4f6bd",
  firebase : {
    apiKey: 'AIzaSyDAx9_qV2eCeW8CN6Ytkh3c5aI3I4nW_6I',
    authDomain: 'afbiz-be6a4.firebaseapp.com',
    databaseURL: 'https://afbiz-be6a4.firebaseio.com',
    projectId: 'afbiz-be6a4',
    storageBucket: 'afbiz-be6a4.appspot.com',
    messagingSenderId: '757994205148',
    appId: '1:757994205148:web:58fc03a7b805c9c0416919'
  },
  appConfig: {
    apiUrl: 'https://sandbox.api.afrikpay.com/api/',
    siteUrl : 'https://afrikbiz.afrikpay.org/',
    orderTypes : [
        {
            title: 'wages',
            icon: ' <i class="fas fa-money-bill-alt fa-sm fa-fw mr-2 text-gray-600"></i>',
            id: 2,
            isActive: true
        },
        {
            title: 'airtime',
            icon: '<i class="fe fe-radio  mr-2 text-gray-600"></i>',
            id: 4,
            isActive: true
        },
        {
            title: 'other',
            icon: '<i class="fas fa-border-none fa-sm fa-fw mr-2 text-gray-600"></i>',
            id: 6,
            isActive: true
        },
        {
            title: 'tuition_fees',
            icon: '<i class="fas fa-graduation-cap fa-sm fa-fw mr-2 text-gray-600"></i>',
            id: 3,
            isActive: false
        },
        {
            title: 'bill',
            icon: '<i class="fas fa-file-invoice fa-sm fa-fw mr-2 text-gray-600"></i>',
            id: 1,
            isActive: false
        },
        {
            title: 'internet',
            icon: '<i class="fas fa-wifi fa-sm fa-fw mr-2 text-gray-600"></i>',
            id: 5,
            isActive: false
        }
    ]
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
