<div class="super-container">
  <div class="wrapper">
    <h1>Hmm.</h1>
    <p>{{'you_are_lost' | translate}}.</p>
    <div class="buttons"><a routerLink="/dashboard">{{'home' | translate}}</a><br /><span>Help me out</span></div>
  </div>
  <div class="space">
    <div class="blackhole"></div>
    <div class="ship"></div>
  </div>
</div>
