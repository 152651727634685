<!-- Sidebar - Brand -->
<a class="sidebar-brand d-flex align-items-center justify-content-center" href="/login">
    <div class="sidebar-brand-icon text-break">
        {{user.organisation.name}}
    </div>
</a>

<!-- Divider -->
<hr class="sidebar-divider my-0">

<!-- Nav Item - Dashboard -->
<li class="nav-item" *ngIf="user.isMaker() || user.isChecker()" [ngClass]="{'active': currentRoute === ('dashboard' || currentRoute == 'order/create')}">
    <a class="nav-link" href="dashboard">
        <i class="fas fa-fw fa-tachometer-alt"></i>
        <span> {{'dashboard' | translate}} </span>
    </a>
</li>

<!-- Divider -->
<hr class="sidebar-divider my-0">

<!-- Nav Item - Dashboard -->
<li class="nav-item" [ngClass]="{'active':( currentRoute === 'company/profile')}">
    <a class="nav-link" href="company/profile">
        <i class="far fa-fw fa-building"></i>
        <span>{{'company_profile' | translate}} </span>
    </a>
</li>
<!-- Divider -->
<hr class="sidebar-divider my-0">

<!-- Nav Item - History -->
<li class="nav-item" *ngIf="user.isMaker() || user.isChecker()" [ngClass]="{'active': (currentRoute === 'company/history')}">
    <a class="nav-link" href="company/history">
        <i class="fas fa-fw fa-history"></i>
        <span>{{'history' | translate}} </span>
    </a>
</li>
<!-- Divider -->
<hr *ngIf="user.isAdmin()" class="sidebar-divider my-0">

<!-- Nav Item - History *ngIf="user.isAdmin()" -->
<li *ngIf="user.isAdmin()"  class="nav-item" [ngClass]="{'active': (currentRoute === 'admin/user/manager')}">
    <a class="nav-link" href="admin/user/manager">
        <i class="fas fa-users-cog fa-list-ul"></i>
        <span>{{'user_manager' | translate}} </span>
    </a>
</li>

<!-- Divider -->
<hr *ngIf="user.isAdmin()" class="sidebar-divider my-0">

<!-- Nav Item - History -->
<li  *ngIf="user.isAdmin()" class="nav-item" [ngClass]="{'active': (currentRoute === 'admin/audit')}">
    <a class="nav-link" href="admin/audit">
        <i class="fas fa-fw fa-list-ul"></i>
        <span>Transactions</span>
    </a>
</li>
