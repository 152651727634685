import { Component, OnInit } from '@angular/core';
import { Transfer } from 'src/classes/Transfer';
import { OrderService } from '../order.service';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.css']
})
export class AuditComponent implements OnInit {

  transactions = [];
  loading = true;
  config : any;
  page = 1;
  itemsperpage = 10
  responsive = true;
  labels: any = {
    previousLabel: '',
    nextLabel: ''
  };
  lang : string;
  dateLocale : string;

  constructor(private orderService : OrderService) { }

  ngOnInit(): void {
    this.setDateLocale();
    this.loadTransactions();
  }

  loadTransactions(){
    this.loading = true;
    this.orderService.getTransactions(this.page , this.itemsperpage).subscribe((response : any)=> {
      this.loading = false;

      if(response.result){
        this.config = {
          currentPage: this.page,
          itemsPerPage: this.itemsperpage,
          totalItems: this.itemsperpage * response.result.totalPage
        };
        this.transactions = response.result.content;
      }else{
        alert(response.message);
      }

    },(error => {
      alert(error)
    }))
  }


  setDateLocale(){
    this.lang = localStorage.getItem('lang');
    if(this.lang == 'en') {
      this.dateLocale = 'MMM d, y, h:mm:ss a';
      return
    }

    this.dateLocale = 'd MMM y, H:mm:ss'
  }


  pageChange(newPage: number) {
    this.page = newPage;
    this.loadTransactions();
  }

}
