export class Error {
    line: number;
    name: string;

    constructor(line: number, name: string) {
        this.line = line;
        this.name = name;
    }
}


export class ErrorFile {
    // tslint:disable-next-line: variable-name
    incorrect_phone = new Array<number>();
    // tslint:disable-next-line: variable-name
    amount_null = new Array<number>();
    // tslint:disable-next-line: variable-name
    choose_bundle = new Array<number>();
    // tslint:disable-next-line: variable-name
    incorrect_card_number = new Array<number>();
    // tslint:disable-next-line: variable-name
    incorrect_meter_id = new Array<number>();
    // tslint:disable-next-line: variable-name
    choose_payment = new Array<number>();
    // tslint:disable-next-line: variable-name
    choose_network = new Array<number>();
}
