import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { StoreService } from './store.service';
import { Injectable } from '@angular/core';
import { User } from 'src/classes/User';

@Injectable({
    providedIn: 'root'
})
export class guard implements CanActivate {
  user : User;

    constructor(private store : StoreService, private router : Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

      if (this.store.getIsLoggedIn()) {
          this.user = new User(this.store.getUserData());
          if(!(this.user.isMaker() || this.user.isChecker())) {
            this.router.navigate(['/user/manager'], {});
            return false;
          }
          return true;
      } else {
          this.router.navigate(['/login'], {
            queryParams: {
              return: state.url
            }
          });
          return false;
      }
    }

}
