import { Company } from './Company';

export class User {
  id: number;
  checker: boolean;
  maker: boolean;
  admin: boolean;
  lastName: string;
  firstName : string;
  phone: string;
  email: string;
  organisation: Company;
  level: Level;
  gender : string;
  token : string;

  constructor(data : any){
    this.id = data.id;
    this.checker = data.checker;
    this.maker = data.maker;
    this.admin = data.admin;
    this.lastName = data.lastName;
    this.firstName = data.firstName;
    this.phone = data.phone;
    this.email = data.email;
    this.gender = data.gender;
    this.token = data.token;
    if(data.organisation) this.organisation = new Company(data.organisation);
    (data.checker)  ? this.level = new Level(data.level) : null;
  }

  public isMaker() : boolean {
    return this.maker;
  }

  public isChecker(){
    return this.checker;
  }

  public isAdmin(){
    return this.admin;
  }
}

class Level {
  id : number;
  slug : string;

  constructor(data: any){
    this.id = data.id;
    this.slug = data.slug;
  }
}

