import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {Chart} from 'chart.js';
import {TranslateService} from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { User } from 'src/classes/User';
import { Order } from 'src/classes/Order';
import { OrderService } from '../order.service';
import { StoreService } from '../store.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  pieChart = [];
  arrTitle = ['order_complete', 'approved_transfer', 'pending_payment', 'validation_pending', 'rejected_transfer'];
  labels = [];
  orderTypes = [];
  user: User;
  selectedOrder: Order;
  showLoader = true;
  pendingOrders = new Array<Order>();
  validatedOrders = new Array<Order>();
  rejectedOrders = new Array<Order>();
  pendingPaymentOrders = new Array<Order>();
  ordersFinished = new Array<Order>();

  constructor(private translate: TranslateService, private store: StoreService, private orderService: OrderService) { }
  bundleList = [
    {
        title: 'Access',
        slug: 'access',
        price: 5000
    },
    {
      title: 'Access plus',
      slug: 'access_plus',
      price: 10000
    },
    {
      title: 'Evasion',
      slug: 'evasion',
      price: 12000
    },
    {
      title: 'Evasion plus',
      slug: 'evasion_plus',
      price: 15000
    }
  ];

    paymentMethodList = [
      {
          title: 'AfrikPay',
          slug: 'afp',
          provider: 'afp'
      },
      {
          title: 'Mobile Money',
          slug: 'mobile_money_cm',
          provider: 'mtn'
      },
      {
          title: 'Orange Money',
          slug: 'orange_money_cm',
          provider: 'orange'
      }
   ];

   lang : string;
   dateLocale : string;

  ngOnInit() {
    this.lang = localStorage.getItem('lang');

    this.orderTypes = environment.appConfig.orderTypes;

    this.setCharLabels();
    this.user = new User(this.store.getUserData());
    this.getOrders();
    this.setDateLocale();
  }

  setDateLocale(){
    if(this.lang == 'en') {
      this.dateLocale = 'MMM d, y, h:mm:ss a';
      return
    }

    this.dateLocale = 'd MMM y, H:mm:ss'
  }

  setCharLabels() {
    // tslint:disable-next-line: prefer-for-of
    for ( let i = 0; i < this.arrTitle.length; i++) {
        this.translate.get(this.arrTitle[i]).subscribe((res: string) => {
            this.labels[i] = res;
        });
    }
  }

  setGraph() {
      // Pie Chart Example
    this.pieChart = new Chart('myPieChart', {
        type: 'doughnut',
        data: {
            labels: this.labels,
            datasets: [{
                data: [this.ordersFinished.length,
                  this.validatedOrders.length,
                    this.pendingPaymentOrders.length,
                    this.pendingOrders.length,
                    this.rejectedOrders.length],
                backgroundColor: ['#1cc88a', '#4e73df', '#36b9cc', '#f6c23e', '#e74a3b'],
                hoverBackgroundColor: ['#319f78;','#254fcc', '#2a95a5', '#ebac0b', '#cf2919'],
                hoverBorderColor: 'rgba(234, 236, 244, 1)',
            }],
        },
        options: {
            maintainAspectRatio: false,
            tooltips: {
                backgroundColor: 'rgb(255,255,255)',
                bodyFontColor: '#858796',
                borderColor: '#dddfeb',
                borderWidth: 1,
                xPadding: 15,
                yPadding: 15,
                displayColors: false,
                caretPadding: 10,
            },
            legend: {
                display: false
            },
            cutoutPercentage: 80,
        },
    });
  }


  getOrders() {
      this.orderService.getAll(1,200).subscribe((response:any) => {

        if(response.code === 200){
          var data = response.result.content;
          (data.length === 0) ? this.showLoader = false : null;

        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < data.length; i++) {

            switch (data[i].status) {
                case 'rejected' : {
                    this.rejectedOrders.push(new Order(data[i]));
                    break;
                }
                case 'validated' : {
                    this.validatedOrders.push(new Order(data[i]));
                    break;
                }
                case 'pending' : {
                    this.pendingOrders.push(new Order(data[i]));
                    break;
                }
                case 'pending_payment' : {
                    this.pendingPaymentOrders.push(new Order(data[i]));
                    break;
                }
                case 'success' :
                case 'failed' :
                case 'finished' : {
                    this.ordersFinished.push(new Order(data[i]));
                    break;
                }
            }

            if (i === data.length - 1 || data.length === 0) {
              this.loadDataScripts();
              this.setGraph();
              this.showLoader = false;
            }
            console.log('done');
        }
        }

      });
  }

  getRealName(name: string, data: Array<{title: string, slug: string}>) {

    if (typeof name === 'undefined' || name === '') { return ''; }
    // tslint:disable-next-line: no-shadowed-variable
    const type = data.find( type => type.slug === name);
    return type.title;
  }

  loadDataScripts() {
    // tslint:disable-next-line: curly
    if (document.getElementById('tableScript')) document.getElementById('tableScript').remove();
    const tableScript = document.createElement('script');
    tableScript.setAttribute('id', 'tableScript');
    tableScript.setAttribute('src', 'assets/js/demo/datatables-demo.js');
    document.body.prepend(tableScript);
  }

}
