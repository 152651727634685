import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { User } from 'src/classes/User';
import { Order } from 'src/classes/Order';
import { StoreService } from 'src/app/store.service';

@Component({
  selector: 'app-table-pending-validation',
  templateUrl: './table-pending-validation.component.html',
  styleUrls: ['./table-pending-validation.component.css']
})
export class TablePendingValidationComponent implements OnInit {

  user: User;
  @Input() pendingOrders = new Array<Order>();
  @Input() dateLocale : string;
  @Output() selectOrder: EventEmitter<Order> = new EventEmitter();

  constructor(private store: StoreService) { }

  ngOnInit() {
      this.user = this.store.getUserData();
  }

  showOrder(order: Order) {
    this.selectOrder.emit(order);
  }

}
