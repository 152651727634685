import { Component, OnInit } from '@angular/core';
import { Company } from 'src/classes/Company';
import { User } from 'src/classes/User';
import { AuthService } from '../auth.service';
import { StoreService } from '../store.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {

  company: Company;
  user: User;
  directors: Array<User>;
  employees: Array<User>;
  loading_directors = true;
  loading_employees = true;

  constructor(private store: StoreService, private auth : AuthService) { }

  ngOnInit() {
   this.user = new User(this.store.getUserData());
   this.company = this.user.organisation; console.log(this.company);
   this.getDirectors();
   this.getEmployees();
  }

  getDirectors(){
    this.auth.getDirectors().subscribe((data:any) =>{
      this.loading_directors = false;
      if(data.code == 200){
        this.directors = data.result;
      }
    })
  }

  getEmployees(){
    this.auth.getEmployees(1, 100).subscribe((data:any) =>{
      this.loading_employees = false;
      if(data.code == 200){
        this.employees = data.result.content;
      }
    })
  }

}
