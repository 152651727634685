import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Order } from 'src/classes/Order';
import { AuthService } from 'src/app/auth.service';
import { User } from 'src/classes/User';
import { StoreService } from 'src/app/store.service';

@Component({
  selector: 'app-table-rejected',
  templateUrl: './table-rejected.component.html',
  styleUrls: ['./table-rejected.component.css']
})
export class TableRejectedComponent implements OnInit {

    user: User;
    @Input() rejectedOrders = new Array<Order>();
    @Input() dateLocale : string;
    @Output() selectOrder: EventEmitter<Order> = new EventEmitter();

    constructor(private store: StoreService) { }

    ngOnInit() {
        this.user = new User(this.store.getUserData());
    }

    showOrder(order: Order) {
        this.selectOrder.emit(order);
        console.log('table ' + order.id);
    }
}
