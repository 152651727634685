import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID,NgModule } from '@angular/core';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { ImageViewerModule } from "ngx-image-viewer";
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { environment } from 'src/environments/environment';
import firebase from 'firebase/app';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { OrderEditComponent } from './order-edit/order-edit.component';
import { OrderCreateComponent } from './order-create/order-create.component';
import { TablePendingValidationComponent } from './table-view/table-pending-validation/table-pending-validation.component';
import { TableRejectedComponent } from './table-view/table-rejected/table-rejected.component';
import { TablePendingPaymentComponent } from './table-view/table-pending-payment/table-pending-payment.component';
import { TableValidatedComponent } from './table-view/table-validated/table-validated.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HistoryComponent } from './history/history.component';
import { CompanyComponent } from './company/company.component';
import { AdminComponent } from './admin/admin.component';
import { LogoutComponent } from './logout/logout.component';
import {AngularFireModule} from '@angular/fire';
import {NgxPaginationModule} from 'ngx-pagination';

import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';

registerLocaleData(localeFr);

import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { TableFinishedComponent } from './table-view/table-finished/table-finished.component';
import { AuditComponent } from './audit/audit.component';
import { ServiceWorkerModule } from '@angular/service-worker';


// // firebase.initializeApp(environment.firebase);
// const userLang = navigator.language;
// firebase.auth().languageCode = userLang;


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    OrderEditComponent,
    OrderCreateComponent,
    TablePendingValidationComponent,
    TableRejectedComponent,
    TablePendingPaymentComponent,
    TableValidatedComponent,
    PageNotFoundComponent,
    HistoryComponent,
    CompanyComponent,
    AdminComponent,
    LogoutComponent,
    TableFinishedComponent,
    AuditComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ImageViewerModule.forRoot(),
    FormsModule,
    TranslateModule,
    // AngularFireAuthModule,
    AngularFireModule.initializeApp (environment.firebase),
    AngularFireModule,
    BrowserAnimationsModule,
    NgxExtendedPdfViewerModule,
    NgxIntlTelInputModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'fr-FR'}],
  bootstrap: [AppComponent]
})
export class AppModule { }


// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
