<!-- Page Wrapper -->
<div id="wrapper">
  <!-- Sidebar -->
  <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled" id="accordionSidebar">
      <app-sidebar></app-sidebar>
  </ul>

  <!-- Content Wrapper -->
  <div id="content-wrapper" class="d-flex flex-column">

      <!-- Main Content -->
      <div id="content">

          <!-- Topbar -->
          <app-header></app-header>
          <div class="container-fluid">
              <!-- Page Heading -->
              <div class="d-sm-flex align-items-center justify-content-between mb-4">
                <h4 class="h4 mb-0 text-gray-800">Transactions
                </h4>

                <div class="mb-4">
                </div>
              </div>

              <!-- Content Row -->

              <div class="card shadow mb-4">
                <!-- Card Header - Dropdown -->
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 class="m-0 font-weight-bold text-primary">
                    </h6> <span></span>
                </div>
                <!-- Card Body -->
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-hover table-bordered table-striped" width="100%" cellspacing="0" *ngIf="!loading">
                          <thead>
                            <tr>
                                <th>ID</th>
                                <th>{{'type' | translate}}</th>
                                <th>{{'status' | translate}}</th>
                                <th>{{'issued_on' | translate}}</th>
                                <th>{{'exec_finished_on' | translate}}</th>
                                <th>{{'account_number' | translate}}</th>
                                <th>{{'amount' | translate}}</th>
                                <th>{{'fees' | translate}}</th>
                            </tr>
                        </thead>
                        <tfoot>
                            <tr>
                                <th>ID</th>
                                <th>{{'type' | translate}}</th>
                                <th>{{'status' | translate}}</th>
                                <th>{{'issued_on' | translate}}</th>
                                <th>{{'exec_finished_on' | translate}}</th>
                                <th>{{'account_number' | translate}}</th>
                                <th>{{'amount' | translate}}</th>
                                <th>{{'fees' | translate}}</th>
                            </tr>
                        </tfoot>
                        <tbody>
                           <tr  *ngFor="let t of transactions | paginate: config; let i=index">
                             <td>{{t.id}}</td>
                             <td class="text-center pt-3"  >
                                <i *ngIf="t.type == 'Debit'" class="fas fa-sort-up text-danger text-big" ></i>
                                <i *ngIf="t.type == 'Credit'" class="fas fa-sort-down text-success text-big"></i>
                             </td>
                             <td class="text-center">
                              <i *ngIf="t.status == 'pending'" class="fas fa-spinner text-info"></i>
                              <i *ngIf="t.status == 'failed'" class="fas fa-times-circle text-danger"></i>
                              <i *ngIf="t.status == 'success'" class="fas fa-check-circle text-success"></i>
                             </td>
                             <td>{{t.createdAt | date:dateLocale}}</td>
                             <td>{{t.executedAt | date:dateLocale}}</td>
                             <td>{{t.account | number}}</td>
                             <td style="text-align: right !important;">XAF {{t.amount | number}} </td>
                             <td style="text-align: right !important;">XAF {{t.fees | number}} </td>
                           </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="d-flex justify-content-end">
                      <span>Pages</span>
                      <pagination-controls

                        [responsive]="responsive"
                        [previousLabel]="labels.previousLabel"
                        [nextLabel]="labels.nextLabel"

                      *ngIf="config" (pageChange)="pageChange($event)" aria-label="Page navigation example" class="my-pagination">
                    </pagination-controls>
                  </div>
                  <div *ngIf="loading" class="d-flex justify-content-center">
                    <img  style="width: 4vw; height: auto;" src="../../assets/img/load.gif" />
                  </div>
                  </div>
              </div>

          </div>
      </div>
      <footer class="sticky-footer bg-white">
          <app-footer></app-footer>
      </footer>
  </div>

</div>
