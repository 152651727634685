<!-- Page Wrapper -->
<div id="wrapper">
    <!-- Sidebar -->
    <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled" id="accordionSidebar">
      <app-sidebar></app-sidebar>
    </ul>

    <!-- Content Wrapper -->
    <div id="content-wrapper" class="d-flex flex-column">
        <!-- Main Content -->
        <div id="content">
            <!-- Topbar -->
            <app-header></app-header>
            <div class="container-fluid">
                <!-- Area Chart -->
                <div class="col-sm-12 col-md-12 pt-10" *ngIf="loading_employees">
                    <div class="d-flex justify-content-center align-items-center">
                        <img style="width: 4vw; height: auto;" src="../../assets/img/load.gif" />
                    </div>
                </div>
                <div class="card shadow mb-4" *ngIf="!loading_employees">
                    <!-- Card Header - Dropdown -->
                    <div class="card-header bg-primary py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 class="m-0 font-weight-bold text-white">{{'user_manager' | translate}}</h6>
                    </div>
                    <!-- Card Body -->
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-hover table-bordered table-striped" id="dataTableUser"
                                width="100%" cellspacing="0">
                                <thead>
                                    <th>Statut</th>
                                    <th> {{ 'name' | translate}}</th>
                                    <th> {{ 'group' | translate}}</th>
                                    <th> {{ 'details' | translate}} </th>
                                    <th> {{ 'action' | translate}}</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let user of employees; let i = index">
                                        <td class="text-center">
                                            <span class="text-danger font-weight-bold" *ngIf="!user.enabled"  >
                                                {{'blocked' | translate}}
                                            </span>
                                            <span class="text-success font-weight-bold" *ngIf="user.enabled">
                                                {{'active' | translate}}
                                            </span>
                                        </td>
                                        <td>{{user.firstName +" "+ user.lastName}}</td>
                                        <td>
                                            <span *ngIf="user.maker" class="badge badge-primary ml-1 mr-2 p-1">Maker</span>
                                            <span *ngIf="user.checker" class="badge badge-danger  mr-2">Checker {{user.level}}</span>
                                            <span *ngIf="user.admin" class="badge badge-success  mr-2 p-1">Admin</span>
                                        </td>
                                        <td>
                                            <span *ngIf="user.enabled && user.enableAt">{{'active' | translate}} {{'since'| translate}} {{user.enableAt | date:dateLocale}}</span>
                                            <span *ngIf="!user.enabled && user.disableAt">{{'blocked' | translate}} {{'since'| translate}} {{user.disableAt | date:dateLocale}}</span>
                                        </td>
                                        <td class="text-center">
                                            <button class="btn btn-info text-center" *ngIf="!user.enabled" (click) = "do(true, i)" role="button">
                                                <i class="fas text-white fa-unlock mr-3"></i> {{'activate' | translate}}
                                            </button>
                                            <button class="btn btn-danger text-center" *ngIf="user.enabled" (click) = "do(false,i)"  role="button">
                                                <i class="fas text-white fa-lock mr-3"></i> {{'block' | translate}}
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>

                            </table>

                            <div class="d-flex justify-content-end">
                              <span>Pages</span>
                              <pagination-controls

                                [responsive]="responsive"
                                [previousLabel]="labels.previousLabel"
                                [nextLabel]="labels.nextLabel"

                              *ngIf="config" (pageChange)="pageChange($event)" aria-label="Page navigation example" class="my-pagination">
                              </pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer class="sticky-footer bg-white">
            <app-footer></app-footer>
        </footer>
    </div>
</div>



<!--Modal complete order-->
<div class="modal" id="approvedModal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="approvedModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header" [ngClass]="{'bg-primary': !actionDone && !errorOccurs, 'bg-success': actionDone, 'bg-danger': errorOccurs}">
                <h5 class="modal-title text-white" id="approvedModalLabel">
                    <span *ngIf="!actionDone  && !errorOccurs"><i class="fas fa-save fa-sm fa-fw mr-2"></i>{{'confirm' | translate}} ?</span>
                    <span *ngIf="actionDone"><i class="fas fa-clipboard-check fa-sm fa-fw mr-2"></i>{{'successful' | translate}}</span>
                    <span *ngIf="errorOccurs"><i class="fas fa-times-circle fa-sm fa-fw text-white mr-2"></i>{{'error_occured' | translate}}</span>
                </h5>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-12 col-md-12 pt-4">
                        <div class="d-flex justify-content-center align-items-center">
                            <img style="width: 4vw; height: auto;" *ngIf="showLoader"   src="../../assets/img/load.gif" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" *ngIf="!actionDone" type="button" (click)="reset()" data-dismiss="modal">{{'no' | translate}}</button>
                <button class="btn btn-primary" *ngIf="!actionDone"  role="button" (click)="confirmAction()">{{'yes' | translate}} </button>
                <button class="btn btn-info" *ngIf="actionDone" type="button" data-dismiss="modal"  role="button">{{'close' | translate}}</button>
            </div>
        </div>
    </div>
</div>
