<div class="card shadow mb-4">
  <!-- Card Header - Dropdown -->
  <div class="card-header bg-success py-1 d-flex flex-row align-items-center justify-content-between">
      <h5 class="m-0 font-weight-bold text-white">{{'order_complete' | translate}}</h5>
  </div>
  <!-- Card Body -->
  <div class="card-body">
      <div class="table-responsive">
          <table class="table table-hover table-bordered table-striped" id="dataTablePendingPayment" width="100%" cellspacing="0">
              <thead>
                  <tr>
                      <th></th>
                      <th>{{'issued_on' | translate}}</th>
                      <th>{{'exec_finished_on' | translate}}</th>
                      <th>{{'type' | translate}}</th>
                      <th>{{'ref_nbr' | translate}}</th>
                      <th>{{'issued_by' | translate}}</th>
                      <th>{{'amount' | translate}}</th>
                      <th>Stats</th>
                      <th></th>
                  </tr>
              </thead>
              <tfoot>
                  <tr>
                      <th></th>
                      <th>{{'issued_on' | translate}}</th>
                      <th>{{'exec_finished_on' | translate}}</th>
                      <th>{{'type' | translate}}</th>
                      <th>{{'ref_nbr' | translate}}</th>
                      <th>{{'issued_by' | translate}}</th>
                      <th>{{'amount' | translate}}</th>
                      <th>Stats</th>
                      <th></th>
                  </tr>
              </tfoot>
              <tbody>
                  <tr *ngFor="let order of ordersFinished; let i = index" (click)="showOrder(order)">
                      <td >{{i+1}}</td>
                      <td class="text-xs">{{order.createddate | date:dateLocale}}</td>
                      <td class="text-xs">{{order.endedAt | date:dateLocale}}</td>
                      <td  class="text-uppercase">{{order.category | translate}}</td>
                      <td  class="text-uppercase">{{order.internalref}} </td>
                      <td  class="text-uppercase">{{order.createdby}}</td>
                      <td  class="text-right">XAF {{order.amount | number}}</td>
                      <td class="text-right text-xs">
                        <span class="text-xs badge badge-danger mr-1">{{order.failed}}/{{order.getTransferNbr()}}</span>
                        <span class="text-xs badge badge-success">{{order.success}}/{{order.getTransferNbr()}}</span>
                      </td>
                      <td>
                          <a href="order/edit/{{order.id}}" class=" d-inline-block mr-2" role="button ">
                            <i class="far text-black-50 fa-edit icon-hover "></i>
                          </a>
                      </td>
                      <!-- <td > {{order.transfers.length}} / {{order.transfers.length + order.pull.transfers.length}} {{'transfer' | translate}}(s) </td> -->
                  </tr>
              </tbody>
          </table>
      </div>
  </div>
</div>
