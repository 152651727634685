import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class cdnService {

  constructor(private httpService: HttpService) { }

  upload_cdn(filename : string, data : string, type : string) {
    let bucket = type == 'pdf'? "pdf" : "csv" ;
    return this.httpService.post('cdn/upload/', JSON.stringify({filename,data,bucket : "home/afrikbiz/"+bucket}));
  }

  upload(filename : string, data : string, type : string) {
    let bucket = type == 'pdf'? "pdf" : "csv" ;
    return this.httpService.post_cdn(JSON.stringify({filename, data, bucket : "home/afrikbiz/"+bucket}));
  }
}
