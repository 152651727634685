import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'AfrikBiz';

  constructor(private translate: TranslateService, router: Router) {

    // window.addEventListener("beforeunload", function(e){
    //   this.localStorage.clear();
    // }, false);

    const arrayLang = ['en', 'fr'];
    const defaultLang = 'fr';
    const userLang = navigator.language;
    let lang =  userLang.substring(0, 2 ).toLowerCase();

    if (arrayLang.lastIndexOf(lang) < 0) {
        lang = defaultLang;
    }
    if (localStorage.getItem('lang')) {
        translate.setDefaultLang(localStorage.getItem('lang'));
    } else {
        translate.setDefaultLang(defaultLang);
    }
  }
}
