<!-- Page Wrapper -->
<div id="wrapper">
    <!-- Sidebar -->
    <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled" id="accordionSidebar">
        <app-sidebar></app-sidebar>
    </ul>
    <!-- Content Wrapper -->
    <div id="content-wrapper" class="d-flex flex-column">
        <!-- Main Content -->
        <div id="content">
            <!-- Topbar -->
            <app-header></app-header>
            <!-- Begin Page Content -->
            <div class="container-fluid">

              <div class="col-sm-12 col-md-12 pt-8" *ngIf="!displayForm">
                <div class="d-flex justify-content-center align-items-center">
                    <img style="width: 4vw; height: auto;" src="../../assets/img/load.gif" />
                </div>
              </div>

                <!-- Page Heading -->
                <div *ngIf="displayForm" class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h4 class="h4 mb-0 text-gray-800"> {{ 'new_order' | translate}}<i class="fas fa-chevron-right fa-xs  m-3"></i> {{order.category | translate}}
                    </h4>
                </div>

                <div class="row mt-3" *ngIf="displayForm">
                    <form action="" class="col-md-8 col-sm-12">
                        <div class="form-group">
                            <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="order.internalref" class="form-control" placeholder=" {{ 'reference_number' | translate}}">
                        </div>

                        <div class="form-group">
                            <textarea [ngModelOptions]="{standalone: true}" [(ngModel)]="order.object" class="form-control" placeholder=" {{ 'order_object' | translate}}" rows="5"></textarea>
                        </div>
                        <div class="form-group pt-2" *ngIf="order.category === 'bill'">
                            <label> {{'provider' | translate}} </label>
                            <select (change)="onBillProviderChange($event.target.value)" class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="order.provider">
                                <option></option>
                                <option *ngFor="let provider of providers" value="{{provider.value}}" > {{provider.name}} </option>
                                <option value="other"> {{ 'other' | translate}} </option>
                            </select>
                            <input *ngIf=" order.provider === 'other'" [ngModelOptions]="{standalone: true}" [(ngModel)]="orderProviderSpecify" type="text" class="form-control mt-3" placeholder=" {{ 'please_specify' | translate}}" />
                        </div>

                        <div class="form-group pt-2" *ngIf="order.category === 'internet'">
                            <label> {{'provider' | translate}} </label>
                            <select (change)="onInternetProviderChange($event.target.value)" class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="internetProvider">
                                <option></option>
                                <option *ngFor="let provider of internetProviders" value="{{provider.value}}" > {{provider.name}} </option>
                            </select>

                        </div>

                        <div *ngIf="( (order.category === 'bill' && (order.provider && (order.provider !== 'other')) || ((order.provider == 'other') && orderProviderSpecify))   || (order.category === 'internet' && internetProvider) || (order.category !== 'bill' && order.category !== 'internet')) &&  order.object && order.internalref"
                            class="alert alert-info col-md-12 center mt-3 p-2 p-md-4 mb-6 col-form-label" role="alert">
                            {{ 'create_order_info' | translate}}
                            <br> {{ 'download_template_here' | translate}}
                            <a *ngIf="order.category === 'airtime'" class="ml-2 text-decoration-none text-primary" href="../../assets/files/airtime.xlsx">
                                <i class="fas fa-file-download"></i> {{ 'click_download' | translate}}
                            </a>
                            <a *ngIf="(order.category === 'bill') && order.provider === 'eneoprepay'" class="ml-2 text-decoration-none text-primary" href="../../assets/files/bill_eneo.xlsx">
                                <i class="fas fa-file-download"></i> {{ 'click_download' | translate}}
                            </a>
                            <a *ngIf="(order.category === 'bill') && order.provider === 'canal'" class="ml-2 text-decoration-none text-primary" href="../../assets/files/bill_canalplus.xlsx">
                                <i class="fas fa-file-download"></i> {{ 'click_download' | translate}}
                            </a>
                            <a *ngIf="((order.category === 'bill' && order.provider === 'other') && ( order.category !== 'airtime')) || order.category === 'wages' || order.category === 'other'" class="ml-2 text-decoration-none text-primary" href="../../assets/files/default.xlsx">
                                <i class="fas fa-file-download"></i> {{ 'click_download' | translate}}
                            </a>
                            <a *ngIf="(order.category === 'internet' && internetProvider === 'yoomee')" class="ml-2 text-decoration-none text-primary" href="../../assets/files/internet_yoomee.xlsx">
                                <i class="fas fa-file-download"></i> {{ 'click_download' | translate}}
                            </a>
                            <a *ngIf="(order.category === 'internet' && internetProvider === 'camtel')" class="ml-2 text-decoration-none text-primary" href="../../assets/files/internet_camtel.xlsx">
                                <i class="fas fa-file-download"></i> {{ 'click_download' | translate}}
                            </a>.
                            <br> <span class="font-weight-bold">{{ 'fill_save' | translate}}</span>
                        </div>

                        <div class="alert alert-warning col-12 col-sm-12 col-md-6 center mt-3 p-2 mb-6 col-form-label" role="alert">
                          <label class="btn btn-outline-primary shadow-sm ml-3" *ngIf="!order.orderProof">
                            <i class="fas fa-file-pdf mr-2"></i> {{ 'upload_voucher' | translate}} (.pdf)
                            <input [disabled]="documentLoading" type="file" accept=".pdf" hidden  (change)="addFile($event)"/>
                          </label>
                          <div class="d-flex align-items-center" *ngIf="order.orderProof">
                            <h6 (click) = "previewVoucher()" > <i class="fas fa-paperclip mr-1"></i>  {{'voucher' | translate}}</h6>
                            <a type="button" (click)="order.deleteProof()"  class="btn btn-danger ml-3"><i class="fas fa-trash-alt text-white"></i></a>
                          </div>

                          <img src="/assets/img/load.gif" *ngIf="documentLoading"  class="img-fluid"  width="50" >
                        </div>

                    </form>
                </div>


                <div *ngIf="getInputErrorStatus()" class="alert alert-danger col-md-12 center mt-5 p-2 mb-1 col-form-label" role="alert">

                    <p *ngFor="let error of errors">
                        <span class="font-weight-bold mr-3" *ngIf="error.line > 0"> {{'line' | translate}} {{error.line}} -  </span>
                        <span>{{error.name | translate}}</span>
                    </p>

                </div>

                <div *ngIf="getFileErrorStatus()" class="alert alert-danger col-md-12 center mt-5 p-2 mb-1 col-form-label" role="alert">

                    <div *ngFor="let error of errorList | keyvalue">
                        <span class="font-weight-bold mr-3" *ngIf="error.value.length > 0">  {{'line' | translate}}  ({{error.value}}) - {{ error.key | translate}} </span>
                    </div>

                </div>


                <!-- DataTales-->
                <div *ngIf="displayForm && (((order.category === 'bill') && (order.provider && (order.provider !== 'other')) || ((order.provider == 'other') && orderProviderSpecify)) ||
                (order.category !== 'bill' && order.category !== 'internet') || (order.category === 'internet' && internetProvider)) &&   order.object && order.internalref" class="card shadow mb-4">
                    <div class="card-header py-3 d-sm-flex align-items-center justify-content-between">
                        <h6 *ngIf="order.amount > 0" class="m-0 font-weight-bold text-primary">
                            {{'total' | translate}}: XAF {{order.amount | number}}
                        </h6> <span></span>
                        <div *ngIf="showImportButton" class="d-flex">
                          <img src="/assets/img/load.gif" *ngIf="signatureLoading"  class="img-fluid"  width="50" >
                          <label class="btn btn-success shadow-sm" *ngIf="!order.orderSignature" >
                            <i class="fas fa-file-csv mr-2"></i> {{ 'import_file' | translate}} (.csv)
                            <input [disabled]="signatureLoading" type="file" accept=".csv" hidden  (change)="convertFile($event)"/>
                          </label>
                          <a type="button" (click)="deleteTransfers()" *ngIf="order.orderSignature"  class="btn btn-danger ml-3"><i class="fas fa-trash-alt text-white"></i></a>
                        </div>

                    </div>
                    <div class="card-body">
                        <h4 class="mb-3">{{'transfer_list' | translate}}</h4>
                        <div class="table-responsive">
                            <table class="table table-bordered table-hover table-striped table-sm" id="transfertOrderTable" width="100%" cellspacing="0">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th style="width: 15%">{{'ref_nbr' | translate}}</th>
                                        <th *ngIf="(order.provider === 'eneoprepay')">{{'meter_id' | translate}}</th>
                                        <th *ngIf="(order.provider === 'canal')">{{'card_number' | translate}}</th>
                                        <th style="width: 15%" *ngIf="(order.provider !== 'canal')">{{'amount' | translate}}</th>
                                        <th *ngIf="(order.provider === 'canal')">{{'bundle' | translate}}</th>
                                        <th *ngIf="!(order.provider === 'canal' || order.provider === 'eneoprepay' || order.category === 'airtime'  || order.category === 'internet')">{{'payment_method' | translate}}</th>
                                        <th *ngIf="order.category === 'airtime'">{{'network_operator' | translate}}</th>
                                        <th *ngIf="!(order.category === 'airtime')">{{'account_number' | translate}}</th>
                                        <th *ngIf="(order.category === 'airtime')">{{'phone' | translate}}</th>
                                        <th>{{'client_name' | translate}}</th>
                                        <th *ngIf="(!order.orderSignature || order.transfers.length === 0)"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngIf="editedRow == -1 && (!order.orderSignature || order.transfers.length === 0)">
                                        <td></td>
                                        <td> <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="currentTransfer.internalref" class="form-control" placeholder=" {{ 'reference_number' | translate}}"></td>
                                        <td *ngIf="(order.provider === 'eneoprepay')"> <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="currentTransfer.meterId" class="form-control" placeholder=" {{ 'meter_id' | translate}}"></td>
                                        <td *ngIf="(order.provider === 'canal')"> <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="currentTransfer.cardNumber" class="form-control numberInput" placeholder=" {{ 'card_number' | translate}}"></td>
                                        <td *ngIf="(order.provider !== 'canal')"> <input type="number" min="100" [ngModelOptions]="{standalone: true}" [(ngModel)]="currentTransfer.amount" class="form-control numberInput" placeholder=" {{ 'amount' | translate}}"></td>
                                        <td *ngIf="(order.provider === 'canal')">
                                            <select [ngModelOptions]="{standalone: true}" [(ngModel)]="currentTransfer.bundleId" class="form-control">
                                                <option [defaultSelected]="true" value="-1"> {{ 'choose_bundle' | translate}} </option>
                                                <option *ngFor="let bundle of bundleList; let i = index" [value]="i"> {{bundle.title}} </option>
                                            </select>
                                        </td>
                                        <td *ngIf="!(order.provider === 'canal' || order.provider === 'eneoprepay' || order.category === 'airtime'  || order.category === 'internet')">
                                            <select [ngModelOptions]="{standalone: true}" [(ngModel)]="currentTransfer.paymentMethodId" class="form-control">
                                                <option [defaultSelected]="true" value ="-1"> {{ 'payment_method' | translate}} </option>
                                                <option *ngFor="let method of paymentMethodList; let i = index" [value]="i"> {{method.title}} </option>
                                            </select>
                                        </td>
                                        <td *ngIf="order.category === 'airtime'">
                                            <select [ngModelOptions]="{standalone: true}" [(ngModel)]="currentTransfer.networkOperatorId" class="form-control">
                                                <option [defaultSelected]="true" value ="-1"> {{ 'network_operator' | translate}} </option>
                                                <option *ngFor="let network of networkOperators; let i = index" [value]="i"> {{network.title}} </option>
                                            </select>
                                        </td>
                                        <td> <input type="text" max="999999999" min="100000000" class="form-control numberInput" [ngModelOptions]="{standalone: true}" [(ngModel)]="currentTransfer.phone" placeholder=" {{ ((order.category == 'wages')? 'account_number' : 'phone') | translate}}"></td>
                                        <td><input type="text" class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="currentTransfer.name" placeholder=" {{ 'client_name' | translate}}"></td>
                                        <td>
                                            <button [disabled] = "signatureLoading" (click)="saveTransfer()" class="btn btn-warning text-center text-black-50 text-xs" role="button">
                                                <i class="fas text-black-50 fa-plus"></i> <br>{{'add' | translate}}
                                            </button>
                                        </td>
                                    </tr>
                                    <tr *ngFor="let transfer of order.transfers; let i = index">
                                        <td>{{i+1}}</td>

                                        <td class="text-uppercase" *ngIf="editedRow !== i">
                                            {{transfer.internalref}}
                                        </td>
                                        <!-- Edited Row begin-->
                                        <td *ngIf="editedRow == i">
                                            <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="editedTransfer.internalref" class="form-control" placeholder=" {{ 'reference_number' | translate}}">
                                        </td>
                                        <!---->

                                        <td *ngIf="(order.provider === 'eneoprepay' && editedRow !== i)">{{transfer.meterId}}</td>
                                        <!-- Edited Row begin-->
                                        <td *ngIf="(order.provider === 'eneoprepay' && editedRow == i)"> <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="editedTransfer.meterId" class="form-control" placeholder=" {{ 'meter_id' | translate}}"></td>
                                        <!---->

                                        <td *ngIf="(order.provider === 'canal' && editedRow !== i)">{{transfer.cardNumber}}</td>
                                        <!-- Edited Row begin-->
                                        <td *ngIf="(order.provider === 'canal' && editedRow == i)"> <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="editedTransfer.cardNumber" class="form-control numberInput" placeholder=" {{ 'card_number' | translate}}"></td>
                                        <!---->

                                        <td *ngIf="(order.provider !== 'canal' && editedRow !== i)">XAF {{transfer.amount | number}}</td>
                                        <!-- Edited Row begin-->
                                        <td *ngIf="(order.provider !== 'canal' && editedRow == i)"> <input type="number" min="100" [ngModelOptions]="{standalone: true}" [(ngModel)]="editedTransfer.amount" class="form-control numberInput" placeholder=" {{ 'amount' | translate}}"></td>
                                        <!---->

                                        <td *ngIf="(order.provider === 'canal' && editedRow !== i)" class="text-uppercase">{{transfer.bundleTitle}}</td>
                                        <!-- Edited Row begin-->
                                        <td *ngIf="(order.provider === 'canal' && editedRow == i)">
                                            <select [ngModelOptions]="{standalone: true}" [(ngModel)]="editedTransfer.bundleId" class="form-control">
                                                <option [defaultSelected]="true" value="-1"> {{ 'choose_bundle' | translate}} </option>
                                                <option *ngFor="let bundle of bundleList; let i = index" [value]="i"> {{bundle.title}} </option>
                                            </select>
                                        </td>
                                        <!---->

                                        <td *ngIf="!(order.provider === 'canal' || order.provider === 'eneoprepay' || order.category === 'airtime' || order.category === 'internet') && editedRow !== i" class="text-uppercase">{{transfer.paymentMethodTitle}}</td>
                                        <!-- Edited Row begin-->
                                        <td *ngIf="!(order.provider === 'canal' || order.provider === 'eneoprepay' || order.category === 'airtime' || order.category === 'internet') && editedRow == i">
                                            <select [ngModelOptions]="{standalone: true}" [(ngModel)]="editedTransfer.paymentMethodId" class="form-control">
                                                <option [defaultSelected]="true" value ="-1"> {{ 'payment_method' | translate}} </option>
                                                <option *ngFor="let method of paymentMethodList; let i = index" [value]="i"> {{method.title}} </option>
                                            </select>
                                        </td>
                                        <!---->

                                        <td *ngIf="order.category === 'airtime' && editedRow !== i" class="text-uppercase">{{transfer.networkOperatorTitle}}</td>
                                        <!-- Edited Row begin-->
                                        <td *ngIf="order.category === 'airtime' && editedRow == i">
                                            <select [ngModelOptions]="{standalone: true}" [(ngModel)]="editedTransfer.networkOperatorId" class="form-control">
                                                <option [defaultSelected]="true" value ="-1"> {{ 'network_operator' | translate}} </option>
                                                <option *ngFor="let network of networkOperators; let i = index" [value]="i"> {{network.title}} </option>
                                            </select>
                                        </td>
                                        <!---->

                                        <td *ngIf="editedRow !== i">{{transfer.phone | number}}</td>
                                        <!-- Edited Row begin-->
                                        <td *ngIf="editedRow == i"> <input type="number" max="999999999" min="100000000" class="form-control numberInput" [ngModelOptions]="{standalone: true}" [(ngModel)]="editedTransfer.phone" placeholder=" {{ ((order.category =='wages')? 'account_number' : 'phone') | translate}}"></td>
                                        <!---->

                                        <td *ngIf="editedRow !== i" class="text-uppercase text-right" style = "font-size: 0.8em !important;">{{transfer.name}}</td>
                                        <!-- Edited Row begin-->
                                        <td *ngIf="editedRow == i"><input type="text" class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="editedTransfer['recipient_name'] || editedTransfer.name" placeholder=" {{ 'client_name' | translate}}"></td>
                                        <!---->

                                        <td *ngIf="editedRow == -1 && editedRow !== i && !order.orderSignature">
                                            <a [routerLink]="" (click)="editTransfer(i)" class=" d-inline-block mr-3" role="button">
                                                <i class="far text-black-50 fa-edit"></i>
                                            </a>
                                            <a [routerLink]="" (click)="askDeletion(i)" class=" d-inline-block" data-toggle="modal" data-target="#deleteTransferModal" role="button">
                                                <i class="far text-danger fa-trash-alt"></i>
                                            </a>
                                        </td>
                                        <!-- Edited Row begin-->
                                        <td *ngIf="editedRow == i">
                                            <button (click)="saveModif()" class="btn btn-success text-center text-black-50 mr-3" role="button">
                                                <i class="fas text-black-50 fa-check"></i>
                                            </button>

                                            <button (click)="cancelModif()" class="btn btn-secondary text-center text-black-50" role="button">
                                                <i class="far text-black-50 fa-times-circle"></i>
                                            </button>
                                        </td>
                                        <!---->
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <!--Previez transfers document-->
                <div id="preview">
                  <div id="preview_container">
                      <div class="bg-primary text-right p-2">
                          <span class="fe fe-x text-white"  id="closePreview"  (click) = "togglePreview(false)"  ></span>
                      </div>
                      <ngx-extended-pdf-viewer
                        *ngIf="pdfSrc"
                        [base64Src]="pdfSrc"
                        height="100%">
                      </ngx-extended-pdf-viewer>



                      <ngx-image-viewer
                          *ngIf="imgSrc"
                          [src]="imgSrc"
                          [config]="{customBtns:[{name: 'link', icon: 'fa fa-paperclip'}]}">
                      </ngx-image-viewer>
                    </div>
                </div>
                <!--End of preview-->

                <div class="pt-6 d-inline" *ngIf="displayForm">
                    <button class="btn btn-secondary">
                        <i class="far fa-times-circle"></i> {{ 'cancel' | translate}}
                    </button>
                    <button [disabled]="!((((order.category === 'bill') && (order.provider && (order.provider !== 'other')) || ((order.provider == 'other') && orderProviderSpecify)) ||
                    (order.category !== 'bill' && order.category !== 'internet') || (order.category === 'internet' && internetProvider)) &&   order.object && order.internalref && (order.transfers.length > 0)) || documentLoading || signatureLoading" class="btn btn-primary ml-4" data-toggle="modal"
                        data-target="#savOrderModal">
                        <i class="fas fa-file-contract mr-2"></i> {{ 'submit' | translate}}
                    </button>
                </div>
            </div>
            <!-- /.container-fluid -->
        </div>
        <footer class="sticky-footer bg-white">
            <app-footer></app-footer>
        </footer>
    </div>

</div>


<!--Modal deletion asked-->
<div class="modal" data-backdrop="static" data-keyboard="false" id="deleteTransferModal" tabindex="-1" role="dialog" aria-labelledby="deleteTransferModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
            <div class="modal-header bg-danger">
                <h5 class="modal-title text-white" id="deleteTransferModalLabel"><i class="far fa-trash-alt fa-sm fa-fw mr-2"></i>{{'delete' | translate}}</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
            </div>
            <div class="modal-body">
                <div class="table-responsive">
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" type="button" data-dismiss="modal">{{'cancel2' | translate}}</button>
                <button class="btn btn-primary" role="button" data-dismiss="modal" (click)="deleteTransfer()">{{'confirm' | translate}} </button>
            </div>
        </div>
    </div>
</div>



<!--Modal complete order-->
<div class="modal" id="savOrderModal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="savOrderModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header" [ngClass]="{'bg-primary': !isCreationOk && !errorOccurs, 'bg-success': isCreationOk, 'bg-danger': errorOccurs}">
                <h5 class="modal-title text-white" id="savOrderModalLabel">
                    <span *ngIf="!isCreationOk  && !errorOccurs"><i class="fas fa-save fa-sm fa-fw mr-2"></i>{{'confirm' | translate}} ?</span>
                    <span *ngIf="isCreationOk"><i class="fas fa-clipboard-check fa-sm fa-fw mr-2"></i>{{'successfully_created' | translate}}</span>
                    <span *ngIf="errorOccurs"><i class="fas fa-times-circle fa-sm fa-fw text-white mr-2"></i>{{'error_occured' | translate}}</span>
                </h5>
            </div>
            <div class="modal-body">
                <p class="text-uppercase"> {{'transfer_request' | translate}} : <span class="text-primary">{{order.internalref}} </span></p>
                <p> {{'total_amount' | translate}} : <span class="text-primary">XAF {{order.amount | number}}</span> ( {{order.transfers.length}} {{'transfer' | translate}}(s) )</p>
                <p class="text-uppercase"> {{'type' | translate}} :
                    <span class="text-primary text-uppercase">{{order.category | translate}}</span>
                    <span *ngIf="orderProviderSpecify"> | {{orderProviderSpecify}}</span>
                    <span *ngIf="order.provider && !orderProviderSpecify"> | {{order.provider}}</span>
                </p>
                <div class="d-flex align-items-center" *ngIf="order.orderProof">
                  <h6> <i class="fas fa-paperclip mr-1"></i>  {{'voucher' | translate}}</h6>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 pt-4">
                        <div class="d-flex justify-content-center align-items-center" *ngIf="showLoader">
                            <img style="width: 4vw; height: auto;" src="../../assets/img/load.gif" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" *ngIf="!isCreationOk" type="button" data-dismiss="modal">{{'no' | translate}}</button>
                <button class="btn btn-primary" *ngIf="!isCreationOk" [disabled]="showLoader" role="button" (click)="submit()">{{'yes' | translate}} </button>
                <a class="btn btn-info" *ngIf="isCreationOk" href="dashboard" role="button">{{'close' | translate}}</a>
            </div>
        </div>
    </div>
</div>
