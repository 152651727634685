import { Transfer } from './Transfer';
import { User } from './User';
import { Validation } from './Validation';

interface IOrder {
  amount: number;
  orderSignature: string;
  typename: string;
  typeId: number;
  object: string;
  comment: string;
  transfers: Array<Transfer>;
  pull: IOrder;
  provider: string;
  category: string;
}

class TimeObject {
  offset: number;
  timestamp: number;
}

export class OrderInit implements IOrder {
  internalref : string;
  amount: number;
  orderSignature: string;
  typename: string;
  typeId: number;
  object: string;
  comment: string;
  userId: number;
  payer: number;
  transfers: Array<Transfer>;
  pull: IOrder;
  provider: string;
  category: string;
  orderProof : string;
  orderProofBase64 : string;
  template : number;

  constructor() {
    this.internalref = '';
    this.amount  =  0;
    this.orderSignature = '';
    this.category = '';
    this.typeId = -1;
    this.object = '';
    this.comment = '';
    this.userId = -1;
    this.payer = null;
    this.transfers = [];
    this.provider = '';
    this.orderProof = "";
    this.orderProofBase64 = "";
  }

  format(){
    for(let i= 0; i< this.transfers.length; i++){
      this.transfers[i].format(this.category, this.provider);
    }
  }

  deleteProof(){
    this.orderProof = null;
  }

  deleteSignature() {
    this.transfers = [];
    this.orderSignature = "";
  }
}

export class Order implements IOrder {
  id: number;
  reference: string;
  internalref: string;
  amount: number;
  orderSignature: string;
  typename: string;
  typeId: number;
  object: string;
  comment: any;
  userId: number;
  user: User;
  payerId: number;
  transfers: Array<Transfer>;
  parent: IOrder;
  provider: string;
  status: string;
  createddate: Date;
  pull: Order;
  createdby: string;
  type: string;
  validations: Array<Validation>;
  category : string;
  orderProof : string;
  transfertNumber : number;
  pending : number;
  failed : number;
  success : number;
  startedAt : string;
  endedAt :string;
  template : number;



  //Contiennent temporairement les transferts


  constructor(data: any) {
    Object.assign(this, data);

    if(this.comment) this.comment = this.comment  ?  JSON.parse(this.comment)[0] : this.comment ;
    this.failed = this.failed || 0;
    this.success = this.success || 0;
    this.pending = this.pending || 0;
    //   this.reference = data.reference;
    //   this.amount  =  data.amount;
    //   this.orderSignature = data.orderSignature;
    //   this.type = data.type ;
    //   this.object = data.object;
    //   this.comment = data.comment;
    //   this.createby = data.createby;
    //   this.validations = data.validations;
    //   this.transfers = data.transfers;
    //   this.pull = data.pull;
    //   this.category = data.category;
    //   this.orderProof = data.orderProof;
    //   this.transfertNumber = data.transfertNumber;
    //   this.internalref = data.internalref;
  }

  async unformat() {

    // const pullTransfers = this.pull.transfers;
    const orderTransfers = this.transfers;

    switch (this.type) {

      case 'airtime':

        // this.pull.transfers = [];
        this.transfers = [];
        // for (let t of pullTransfers) {
        //   t = new Transfer(t);
        //   t.network_operator = t.service;
        //   this.pull.transfers.push(t);
        // }

        for (let t of orderTransfers) {
          t = new Transfer(t);
          t.network_operator = t.service;
          this.transfers.push(t);
        }

        break;

      case 'bill' :

        if(this.transfers.length > 0 ){
          this.provider = this.transfers[0].service;
        } else {
          if(this.pull.transfers.length > 0 ){
            this.provider = this.pull.transfers[0].service;
          }
        }

        // this.pull.transfers = [];
        this.transfers = [];

        // for (let t of pullTransfers) {
        //   t = new Transfer(t);
        //   t.cardNumber = t.reference;
        //   t.meterId = t.reference;
        //   this.pull.transfers.push(t);
        // }

        for (let t of orderTransfers) {
          t = new Transfer(t);
          t.cardNumber = t.reference;
          t.meterId = t.reference;
          this.transfers.push(t);
        }

        break;

      default:
        // this.pull.transfers = [];
        this.transfers = [];

        // for (let t of pullTransfers) {
        //   t = new Transfer(t);
        //   t.phone = t.reference;
        //   t.setP();
        //   this.pull.transfers.push(t);
        // }

        for (let t of orderTransfers) {

          t = new Transfer(t);
          t.phone = t.reference;
          t.setP();
          this.transfers.push(t);
        }
        break;
    }

  }

  getTransferNbr(){
    return this.failed + this.success + this.pending;
  }

}
