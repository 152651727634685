<!-- Page Wrapper -->
<div id="wrapper">
    <!-- Sidebar -->
    <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled" id="accordionSidebar">
        <app-sidebar></app-sidebar>
    </ul>

    <!-- Content Wrapper -->
    <div id="content-wrapper" class="d-flex flex-column">

        <!-- Main Content -->
        <div id="content">
            <!-- Topbar -->
            <app-header></app-header>
            <div class="container-fluid">
                <!-- Page Heading -->
                <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h4 class="h4 mb-0 text-gray-800">{{ 'dashboard' | translate}}
                    </h4>

                    <div class="mb-4" *ngIf="user.isMaker()">
                        <a href="#" class=" d-sm-inline-block btn btn-sm btn-primary shadow-sm" id="operationDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-plus fa-sm text-white"></i> {{ 'new_order' | translate}}
                        </a>
                        <!-- Dropdown - User Information -->
                        <div class="dropdown-menu animated--fade-in" aria-labelledby="operationDropdown">
                            <a *ngFor="let type of orderTypes" class="dropdown-item" [ngClass]=" {'disabled': !type.isActive}" routerLink="/order/create/{{type.title}}">
                                <span [innerHTML]="type.icon"></span> {{ type.title | translate}}
                            </a>
                            <div class="dropdown-divider"></div>
                        </div>
                    </div>
                </div>

                <!-- Content Row -->
                <div class="row">
                    <!-- Area Chart -->
                    <div class="col-sm-12 col-md-9 pt-4" *ngIf="showLoader">
                        <div class="d-flex justify-content-center align-items-center">
                            <img style="width: 4vw; height: auto;" src="../../assets/img/load.gif" />
                        </div>
                    </div>
                    <div *ngIf="user.isMaker() && !showLoader" class="col-sm-12 col-md-9">
                        <app-table-finished (selectOrder)="selectedOrder=$event" *ngIf="ordersFinished.length > 0" [ordersFinished]="ordersFinished"  [dateLocale]="dateLocale"  ></app-table-finished>
                        <app-table-rejected (selectOrder)="selectedOrder=$event" *ngIf="rejectedOrders.length > 0" [rejectedOrders]="rejectedOrders"  [dateLocale]="dateLocale"  ></app-table-rejected>
                        <app-table-validated (selectOrder)="selectedOrder=$event" *ngIf="validatedOrders.length > 0" [validatedOrders]="validatedOrders" [dateLocale]="dateLocale" ></app-table-validated>
                        <app-table-pending-payment (selectOrder)="selectedOrder=$event" *ngIf="pendingPaymentOrders.length > 0" [pendingPaymentOrders]="pendingPaymentOrders"  [dateLocale]="dateLocale" ></app-table-pending-payment>
                        <app-table-pending-validation (selectOrder)="selectedOrder=$event" *ngIf="pendingOrders.length > 0" [pendingOrders]="pendingOrders" [dateLocale]="dateLocale" ></app-table-pending-validation>
                    </div>

                    <div *ngIf="user.isChecker() && !user.isMaker() && !showLoader" class="col-sm-12 col-md-9">
                      <app-table-finished (selectOrder)="selectedOrder=$event" *ngIf="ordersFinished.length > 0" [ordersFinished]="ordersFinished"  [dateLocale]="dateLocale"  ></app-table-finished>
                        <app-table-validated (selectOrder)="selectedOrder=$event" *ngIf="validatedOrders.length > 0" [validatedOrders]="validatedOrders" [dateLocale]="dateLocale" ></app-table-validated>
                        <app-table-pending-validation (selectOrder)="selectedOrder=$event" *ngIf="pendingOrders.length > 0" [pendingOrders]="pendingOrders" [dateLocale]="dateLocale" ></app-table-pending-validation>
                        <app-table-pending-payment (selectOrder)="selectedOrder=$event" *ngIf="pendingPaymentOrders.length > 0" [pendingPaymentOrders]="pendingPaymentOrders" [dateLocale]="dateLocale" ></app-table-pending-payment>
                        <app-table-rejected (selectOrder)="selectedOrder=$event" *ngIf="rejectedOrders.length > 0" [rejectedOrders]="rejectedOrders" [dateLocale]="dateLocale" ></app-table-rejected>
                    </div>

                    <!-- Pie Chart -->
                    <div class="col-sm-12 col-md-3">
                        <div class="card shadow mb-4">
                            <!-- Card Header - Dropdown -->
                            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 class="m-0 font-weight-bold text-primary">Stats</h6>
                                <div class="dropdown no-arrow">
                                    <a class="dropdown-toggle" href="#" role="button">
                                        <i class="fas fa-sync-alt fa-sm fa-fw text-gray-400"></i>
                                    </a>
                                </div>
                            </div>
                            <!-- Card Body -->
                            <div class="card-body">
                                <div class="chart-pie pt-4 pb-2">
                                    <canvas id="myPieChart"></canvas>
                                </div>
                                <div class="mt-4 row">
                                    <span class="col-12 text-xs mb-1"> <i class="fas fa-circle text-success"></i> {{'order_complete' | translate}}</span>
                                    <span class="col-12 text-xs mb-1"> <i class="fas fa-circle text-primary"></i> {{'approved_transfer' | translate}}</span>
                                    <span class="col-12 text-xs mb-1"> <i class="fas fa-circle text-info"></i> {{'pending_payment' | translate}}</span>
                                    <span class="col-12 text-xs mb-1"> <i class="fas fa-circle text-warning"></i> {{'validation_pending' | translate}}</span>
                                    <span class="col-12 text-xs mb-1"> <i class="fas fa-circle text-danger"></i> {{'rejected_transfer' | translate}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <footer class="sticky-footer bg-white">
            <app-footer></app-footer>
        </footer>
    </div>

</div>
