<!-- Topbar -->
<nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    <!-- Sidebar Toggle (Topbar) -->
    <button id="sidebarToggleTop" class="btn btn-link  rounded-circle mr-3">
        <i class="fa fa-bars"></i>
    </button>
    <!-- Topbar Navbar -->
    <ul class="navbar-nav ml-auto">
        <li class="nav-item no-arrow mx-1">
            <a class="nav-link" href="#">
                <i class="fas fa-wallet fa-fw text-gray-600"></i>
                <img *ngIf="loading" style="width: 4vw; height: auto;" src="../../assets/img/load.gif" />
                <span *ngIf="!loading" class="text-success ml-1">{{balance | number}} XAF</span>
            </a>
        </li>

        <!-- Nav Item - User language -->
        <li class="nav-item dropdown arrow">
            <a class="nav-link dropdown-toggle" href="#" href="#" id="langDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="d-lg-inline text-gray-400 small">{{ otherLang | uppercase}}</span>
            </a>
            <!-- Dropdown - User Information -->
            <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="langDropdown">
                <span class="dropdown-item span-link" (click)="useLanguage('en')">
                    EN
                </span>
                <div class="dropdown-divider"></div>
                <span class="dropdown-item span-link" (click)="useLanguage('fr')">
                    FR
                </span>
            </div>
        </li>
        <div class="topbar-divider d-none d-sm-block"></div>
        <!-- Nav Item - User Information -->
        <li class="nav-item dropdown no-arrow">
            <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="mr-2 d-none d-lg-inline text-gray-600 small" *ngIf="user">{{user.lastname}}</span>
                <img class="img-profile rounded-circle" src="/assets/img/avatar_placeholder.png">
            </a>
            <!-- Dropdown - User Information -->
            <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                <a class="dropdown-item" href="#" data-toggle="modal" data-target="#profileModal">
                    <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i> {{'profile' | translate}}
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item"  data-toggle="modal" data-target="#logoutModal">
                    <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i> {{'logout' | translate}}
                </a>
            </div>
        </li>

    </ul>
</nav>
<!-- End of Topbar -->


<!--Modal profile order-->
<div class="modal" id="profileModal" tabindex="-1" role="dialog" aria-labelledby="profileModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h5 class="modal-title text-white" id="profileModalLabel"><i class="fas fa-user-circle fa-sm fa-fw mr-2"></i>{{'profile' | translate}}</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="user">
                <h6 class="text-uppercase">{{user.firstname}} {{user.lastname}} </h6>
                <small *ngIf="user.isChecker()">{{user.level.slug}} <span >{{user.level.slug}}</span> </small>
                <p>
                    <i class="fas fa-at fa-sm fa-fw mr-2 text-gray-600"></i>{{user.email}}
                    <br>
                    <i class="fas fa-mobile fa-sm fa-fw mr-2 text-gray-600"></i>{{user.phone}}
                </p>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" type="button" data-dismiss="modal">{{'close' | translate}}</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="logoutModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h5 class="modal-title text-white" id="logoutModalLabel"><i class="fas fa-sign-out-alt fa-sm fa-fw mr-2"></i>{{'logout' | translate}} ?</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body" >
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" type="button" data-dismiss="modal">{{'no' | translate}}</button>
                <a href="/logout" class="btn btn-primary" type="button">{{'yes' | translate}}</a>
            </div>
        </div>
    </div>
</div>
