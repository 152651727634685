const providers = [
    {
        value: 'canal',
        name: 'Canal plus'
    },
    {
      value: 'eneo',
      name: 'ENEO'
  }
];
const bundleList = [
    {
        title: 'Access',
        slug: 'access',
        price: 5000
    },
    {
      title: 'Access plus',
      slug: 'access_plus',
      price: 10000
    },
    {
      title: 'Evasion',
      slug: 'evasion',
      price: 12000
    },
    {
      title: 'Evasion plus',
      slug: 'evasion_plus',
      price: 15000
    }
];
const networkOperators = [
    {
        title : 'MTN',
        slug: 'mtn'
    },
    {
        title: 'Orange',
        slug: 'orange'
    },
    {
        title: 'Nexttel',
        slug: 'nexttel'
    }
];

const paymentMethodList = [
  {
      title: 'AfrikPay',
      slug: 'afrikpay',
      provider: 'afrikpay'
  },
  {
      title: 'Mobile Money',
      slug: 'mtn_mobilemoney_cm',
      provider: 'mtn'
  },
  {
      title: 'Orange Money',
      slug: 'orange_money_cm',
      provider: 'orange'
  },
  {
      title: 'Express Union Mobile',
      slug: 'express_union_mobilemoney',
      provider: 'eum'
  },
  {
      title: 'Afriland First Bank',
      slug: 'afriland',
      provider: 'bank'
  },
  {
      title: 'EcoBank',
      slug: 'ecobank',
      provider: 'bank'
  },
  {
      title: 'BICEC',
      slug: 'bicec',
      provider: 'bank'
  },
  {
      title: 'CBC',
      slug: 'cbc',
      provider: 'bank'
  },
  {
      title: 'EcoBank',
      slug: 'ecobank',
      provider: 'bank'
  },
  {
      title: 'SGC',
      slug: 'sgc',
      provider: 'bank'
  },
  {
      title: 'SCB',
      slug: 'scb',
      provider: 'bank'
  }
];

export class Transfer {
  id: number;
  internalref : string;
  phone: string;
  name: string;
  reference: string;
  meterId: string;
  cardNumber: string;
  amount: number;
  bundleTitle: string;
  bundle: string;
  bundleId: number; //
  paymentMethod: string;
  paymentMethodTitle: string;
  paymentMethodId: number; //
  networkOperator: string;
  networkOperatorTitle: string;
  networkOperatorId: number; //
  network_operator: string;
  internal_ref_number: string;
  meter_id: string;
  payment_method: string;
  document : string;
  documentname : string;
  service : string;
  type : string;
  status : string;
  doneAt: string;


  constructor(data:any) {
    if(!data){
      this.phone = '';
      this.name = '';
      this.reference  = '';
      this.meterId = '';
      this.cardNumber = '';
      this.amount = 0;
      this.bundle = '';
      this.paymentMethod = '';
      this.networkOperator = '';
      this.document = '';
      this.documentname = '';
      this.type = '';
      this.internalref = "";
    } else {
      Object.assign(this,data);
    }
  }

  setP() {

    for(var i=0; i < paymentMethodList.length; i++){
      if(paymentMethodList[i].slug == this.service){
        var obj = paymentMethodList[i];
        this.paymentMethodTitle = obj.title;
        this.paymentMethod = obj.slug;
        this.paymentMethodId = i;
      }
    }
  }

  format(type: string, provider : string) {

    switch (type) {
      case 'airtime':
        this.service = this.networkOperator;
        this.reference = this.phone;
        break;

      case 'bill':
        this.service = provider ;
        this.reference = this.meterId || this.cardNumber;
        break;

      default:
          this.service = this.paymentMethod ;
          this.reference = this.phone;
          break;
    }
  }
}

