import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from '../order.service';
import { User } from 'src/classes/User';
import { Order, OrderInit } from 'src/classes/Order';
import { Transfer } from 'src/classes/Transfer';
import { Validation } from 'src/classes/Validation';
import { ErrorFile, Error } from 'src/classes/Error';
import { environment } from 'src/environments/environment';
import { stringify } from 'querystring';
import { StoreService } from '../store.service';
declare var $: any;

@Component({
  selector: 'app-order-edit',
  templateUrl: './order-edit.component.html',
  styleUrls: ['./order-edit.component.css']
})
export class OrderEditComponent implements OnInit {

    providers = [
        {
            value: 'canal',
            name: 'Canal plus'
        },
        {
          value: 'eneoprepay',
          name: 'ENEO'
      }
    ];
    bundleList = [
        {
            title: 'Access',
            slug: 'access',
            price: 5000
        },
        {
          title: 'Access plus',
          slug: 'access_plus',
          price: 10000
        },
        {
          title: 'Evasion',
          slug: 'evasion',
          price: 12000
        },
        {
          title: 'Evasion plus',
          slug: 'evasion_plus',
          price: 15000
        }
    ];

    paymentMethodList = [
        {
            title: 'AfrikPay',
            slug: 'afrikpay',
            provider: 'afrikpay'
        },
        {
            title: 'Mobile Money',
            slug: 'mtn_mobilemoney_cm',
            provider: 'mtn'
        },
        {
            title: 'Orange Money',
            slug: 'orange_money_cm',
            provider: 'orange'
        },
        {
            title: 'Express Union Mobile',
            slug: 'express_union_mobilemoney',
            provider: 'eum'
        },
        {
            title: 'Afriland First Bank',
            slug: 'afriland',
            provider: 'bank'
        },
        {
            title: 'EcoBank',
            slug: 'ecobank',
            provider: 'bank'
        },
        {
            title: 'BICEC',
            slug: 'bicec',
            provider: 'bank'
        },
        {
            title: 'CBC',
            slug: 'cbc',
            provider: 'bank'
        },
        {
            title: 'EcoBank',
            slug: 'ecobank',
            provider: 'bank'
        },
        {
            title: 'SGC',
            slug: 'sgc',
            provider: 'bank'
        },
        {
            title: 'SCB',
            slug: 'scb',
            provider: 'bank'
        }
    ];

    networkOperators = [
        {
            title : 'MTN',
            slug: 'mtn'
        },
        {
            title: 'Orange',
            slug: 'orange'
        },
        {
            title: 'Nexttel',
            slug: 'nexttel'
        }
    ];

    internetProviders = [
        {
            name: 'Yoomee',
            value: 'yoomee'
        },
        {
            name: 'Camtel',
            value: 'camtel'
        }
    ];

    regExp = {
        mtn: [/^6((5[0-4]{1}[0-9]{6})|(7[0-9]{1}[0-9]{6})|(8[0-4]{1}[0-9]{6}))$/g],
        orange: [/^6((9[0-9]{7})|5[5-9]{1}[0-9]{6})$/g],
        nexttel: [/^6((6[0-9]{7})|(85[0-9]{6}))$/g],
        camtel: [/^(222|233|242|243)[0-9]{6}$|^(62)[0-9]{7}$/g],
        yoomee : [/^(222|233|242|243)[0-9]{6}$/g],
        afrikpay: [],
        eum: [],
        bank : [/[0-9]{30,}/g]
    };

    tagErrors = {
        phone: 'incorrect_phone',
        amount: 'amount_null',
        bundle: 'choose_bundle',
        card: 'incorrect_card_number',
        meterId: 'incorrect_meter_id',
        paymentMethod: 'choose_payment',
        network: 'choose_network'
    };

  user: User;
  editedOrder: Order;
  editedRow: number;
  currentTransfer: Transfer;
  errors: Error[];
  errorList = new ErrorFile();
  deletedRow: number;
  orderTypeList = environment.appConfig.orderTypes;
  editedTransfer = new Transfer(null);
  deletedTransfer = new Transfer(null);
  showImportButton = false;
  result: any;
  temp: number;
  internetProvider: string;
  isCreationOk = false;
  showLoader = false;
  errorOccurs = false;
  comments: string;
  tooShortText = false;
  orderLoaded = false;
  validationsLoaded = false

  pdfSrc : string;
  imgSrc = null;
  lang : string;
  tem_transfers = [
    {
        phone:"444588776622338",
        name:"DIDIER SONG",
        reference:"444588776622338",
        amount:10000,
        paymentMethod:"ecobank",
        internalref:"WFFWFW",
        paymentMethodTitle:"EcoBank",
        service:"ecobank"
    },
    {
        phone:"698507735",
        name:"DIDIER DAN",
        reference:"698507735",
        amount:15000,
        paymentMethod:"orange_money_cm",
        internalref:"DKFMWF",
        paymentMethodTitle:"Orange Money",
        service:"orange_money_cm"
    }
];

config : any;
page = 1;
itemsperpage = 10;
labels: any = {
  previousLabel: '',
  nextLabel: ''
};
loaded_transfers = false;
transfers : Array<Transfer>;
dateLocale : string;
  errorMessage: string;


  constructor(private activatedRoute: ActivatedRoute, private router: Router,
              private store : StoreService ,private orderService: OrderService) { }

  ngOnInit() {
    this.user = new User(this.store.getUserData());
    this.lang = localStorage.getItem('lang');
    this.activatedRoute.paramMap.subscribe(params => {
        const id = params.get('id');
        if (!id) { this.router.navigateByUrl('**'); }
        this.orderService.get(id, this.user.token).subscribe((response:any) => {
            if (response.code === 200) {
                response.result.transfers = [] ;
                // response.result.pull = {transfers : this.tem_transfers};
                this.editedOrder = new Order(response.result);

                this.orderLoaded = true;
                this.getTransfers();
                this.getvalidations();
            }
        },(error => {
            if(error) console.log(error);
        }) );

        this.regExp.afrikpay = [this.regExp.camtel[0], this.regExp.mtn[0], this.regExp.orange[0], this.regExp.nexttel[0]];
        this.initTransferRow();
        this.initErrorList();
        this.editedRow = -1;
        this.errors = [];
        this.comments = '';
        this.setDateLocale();

    });
  }

  // async  createFile(){
  //   let response = await fetch(this.file);
  //   let data = await response.blob();
  //   let metadata = {
  //     type: 'application/pdf'
  //   };
  //   let file = new File([data], "test.pdf", metadata);

  //   console.log(file);
  // }

  setDateLocale(){
    if(this.lang == 'en') {
      this.dateLocale = 'MMM d, y, h:mm:ss a';
      return
    }

    this.dateLocale = 'd MMM y, H:mm:ss'
  }

  pageChange(newPage: number) {
    this.page = newPage;
    this.getTransfers();
  }

  getTransfers(){
    this.orderService.getTransfers(this.editedOrder.reference, this.page, this.itemsperpage).subscribe((response : any) => {
      if(response.code == 200){
        this.editedOrder.transfers = response.result.content;
        this.config = {
          currentPage: this.page,
          itemsPerPage: this.itemsperpage,
          totalItems: this.itemsperpage * response.result.totalPage
        };
        this.editedOrder.unformat().then(() =>{
            for (let i = 0; i < this.editedOrder.transfers.length; i++) {
                this.setIdsAndTitle(this.editedOrder.transfers[i]);
            }
            this.loaded_transfers = true;
        })
      }
    })
  }

  getvalidations(){
    this.orderService.getValidations(this.editedOrder.reference).subscribe((response : {code : number, result : Array<Validation>}) => {
      this.validationsLoaded = true;
      if(response.code == 200){
        this.editedOrder.validations = response.result;
      }
    })
  }

  setIdsAndTitle(transfer: Transfer) {

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.paymentMethodList.length; i++) {
        if (this.paymentMethodList[i].slug === transfer.payment_method) { transfer.paymentMethodId = i; }
    }

    for (let i = 0; i < this.bundleList.length; i++) {
        if (this.bundleList[i].slug === transfer.bundle) {  transfer.bundleId = i; }
    }

    for (let i = 0; i < this.networkOperators.length; i++) {
        if (this.networkOperators[i].slug === transfer.network_operator) { transfer.networkOperatorId = i; }
    }
    const bundle = this.bundleList.find( x => x.slug === transfer.bundle);
    transfer.bundleTitle = (bundle) ? bundle.title : '';

    const method = this.paymentMethodList.find( methodObj => methodObj.slug === transfer.paymentMethod);
    transfer.paymentMethodTitle = (method) ? method.title : '';


    const op = this.networkOperators.find( x => x.slug == transfer.service);
    transfer.networkOperatorTitle = (op) ? op.title : '';


    transfer.networkOperator = transfer.network_operator;
  }

  getRealName(name: string, data: Array<{title: string, slug: string}>) {

    if (typeof name === 'undefined' || name === '') { return ''; }
    // tslint:disable-next-line: no-shadowed-variable
    const type = data.find( type => type.slug === name);
    return type.title;
  }

  initTransferRow() {
    this.currentTransfer = new Transfer(null);
    this.currentTransfer.bundleId = -1;
    this.currentTransfer.paymentMethodId = -1;
    this.currentTransfer.networkOperatorId = -1;
  }

  initErrorList() {
      this.errorList = new ErrorFile();

      this.errors = new Array<Error>();
  }



  checkPhone(phone: any, regExp: RegExp[]) {

    console.log(phone + regExp);

    let output = false;

    if ( typeof phone === 'undefined') { return output; }
    if ( typeof regExp === 'undefined') { return output; }

   // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < regExp.length; i++) {
       if (phone.toString().match(regExp[i])) {
           output = true;
       }
    }
    return output;
 }

 checkCardNumber(num: string) {
    if (typeof num === 'undefined') { return false; }
    return (num.toString().length === 14) ? true : false;
 }

 checkMeterId(num: string) {
   if (typeof num === 'undefined') { return false; }
   return (num.length === 12) ? true : false;
}


 saveModif() {
   this.initErrorList();
   this.setProviders(this.editedTransfer, 'id', -1);
   const m = this.editedTransfer.amount || 0;
   this.editedOrder.amount = this.editedOrder.amount - this.temp + m;
   this.editedOrder.pull.transfers[this.editedRow] = this.editedTransfer;
   if (this.errors.length === 0) { this.editedRow = -1; }
 }

 getInputErrorStatus() {
    if (this.errors.length > 0) {
        return true;
    }
    return false;
}

getFileErrorStatus() {
  const arr = Object.values(this.errorList);

  // tslint:disable-next-line: prefer-for-of
  for (let i = 0; i < arr.length; i++) {
      if ( arr[i].length > 0) {
          return true;
      }
  }

  return false;
}

getErrorList() {
  return Object.entries(this.errorList) || [];
}

 cancelModif() {
   this.editedRow = -1;
   this.initErrorList();
 }

 askDeletion(transferIndex: number) {
   this.deletedRow = transferIndex;
   this.deletedTransfer = this.editedOrder.transfers[transferIndex];
   this.temp = this.deletedTransfer.amount;
 }

 deleteTransfer() {
   this.editedOrder.transfers.splice(this.deletedRow, 1);
   this.editedOrder.amount -= this.temp;
   if (this.editedOrder.transfers.length === 0) {
       this.showImportButton = true;
   }
 }

 editTransfer(trannsferIndex: number) {
   this.temp = this.editedOrder.pull.transfers[trannsferIndex].amount || 0;
   this.editedTransfer = this.editedOrder.pull.transfers[trannsferIndex];
   this.editedRow = trannsferIndex;
   console.log(this.temp);
 }

  saveTransfer() {
    this.errors = [];
    console.log(this.currentTransfer);

    if (this.setProviders(this.currentTransfer, 'id', -1)) {
         // hide the import button if the add button is used
        this.showImportButton = false;
        this.editedOrder.transfers.push(this.currentTransfer);
        const m = this.currentTransfer.amount || 0;
        this.editedOrder.amount += m;
        this.initTransferRow();
        this.showImportButton = false;
    }
  }

  addTransferFromFile(transfer: Transfer) {
    this.editedOrder.transfers.push(transfer);
    const m = transfer.amount || 0;
    this.editedOrder.amount += m;
  }


  setProviders(transfer: Transfer, type: string, line: number) {
    let isOk = true;
    let error;
    console.log(transfer);

    if (this.editedOrder.provider === 'canalplus') {

        if (type === 'id') {
            // Checking data from form inputs

            if ( transfer.bundleId < 0) {
                error = new Error(0, this.tagErrors.bundle);
                this.errors.push(error);
                isOk = false;
            }

            if (!this.checkPhone(transfer.phone, this.regExp.afrikpay)) {
                error = new Error(0, this.tagErrors.phone);
                this.errors.push(error);
                isOk = false;
            }

            if (!this.checkCardNumber(transfer.cardNumber)) {
                error = new Error(0, this.tagErrors.card);
                this.errors.push(error);
                isOk = false;
            }
            if (isOk) {
                // set the bundle in transfer object
                transfer.bundle = this.bundleList[transfer.bundleId].slug;
                transfer.bundleTitle = this.bundleList[transfer.bundleId].title;
                transfer.amount = this.bundleList[transfer.bundleId].price;
            }

        } else {
            // Checking data from file

            // tslint:disable-next-line: no-shadowed-variable
            const bundle = this.bundleList.find(bundle => bundle.slug === transfer.bundle);

            // checking bundle
            if ( typeof bundle !== 'undefined' && bundle) {
                if ( transfer.bundleId < 0) {
                    this.errorList.choose_bundle.push(line + 1);
                    isOk = false;
                }

            } else {
                this.errorList.choose_bundle.push(line + 1);
                isOk = false;
            }

            // checking phone
            if ( typeof transfer.phone !== 'undefined' ) {
                if (!this.checkPhone(transfer.phone, this.regExp.afrikpay)) {
                    this.errorList.incorrect_phone.push(line + 1);
                    isOk = false;
                }
            } else {
                this.errorList.incorrect_phone.push(line + 1);
                isOk = false;
            }

            // checking card number
            if ( typeof transfer.cardNumber !== 'undefined') {
                if (!this.checkCardNumber(transfer.cardNumber)) {
                    this.errorList.incorrect_card_number.push(line + 1);
                    isOk = false;
                }
            } else {
                this.errorList.incorrect_card_number.push(line + 1);
                isOk = false;
            }

            // is everything is okay
            if (isOk) {
                transfer.bundleTitle = bundle.title;
                transfer.amount = bundle.price;
            }
        }

    }

    // tslint:disable-next-line: max-line-length
    if (!(this.editedOrder.provider === 'canalplus' || this.editedOrder.provider === 'eneo' || this.editedOrder.category === 'airtime' || this.editedOrder.category === 'internet')) {
        // set the payment method in transfer object
        if (type === 'id') {
            if ( transfer.paymentMethodId < 0) {
                error = new Error(0, this.tagErrors.paymentMethod);
                this.errors.push(error);
                isOk = false;

                if (!this.checkPhone(transfer.phone, this.regExp.afrikpay)) {
                    error = new Error(line + 1, this.tagErrors.phone);
                    this.errors.push(error);
                    isOk = false;
                }

            } else {

                if (!this.checkPhone(transfer.phone, this.regExp[this.paymentMethodList[transfer.paymentMethodId].provider] )) {
                    error = new Error(0, this.tagErrors.phone);
                    this.errors.push(error);
                    isOk = false;
                }
            }

            if ( typeof transfer.amount === 'undefined' || transfer.amount < 100) {
                error = new Error(0, this.tagErrors.amount);
                this.errors.push(error);
                isOk = false;

            }

            if (isOk) {
                transfer.paymentMethod = this.paymentMethodList[transfer.paymentMethodId].slug;
                transfer.paymentMethodTitle = this.paymentMethodList[transfer.paymentMethodId].title;
            }

        } else {

            // tslint:disable-next-line: no-shadowed-variable
            const paymentMethod = this.paymentMethodList.find(payment => payment.slug === transfer.paymentMethod);

            if ( typeof paymentMethod !== 'undefined' && paymentMethod) {

                if (!this.checkPhone(transfer.phone, this.regExp[paymentMethod.provider] )) {
                    this.errorList.incorrect_phone.push(line + 1);
                    isOk = false;
                }

            } else {

                this.errorList.choose_payment.push(line + 1);
                isOk = false;
            }


            if ( typeof transfer.amount === 'undefined' || transfer.amount < 100) {
                this.errorList.amount_null.push(line + 1);
                isOk = false;

            }

            if (isOk) {
                transfer.paymentMethodTitle = paymentMethod.title;
            }
        }
    }

    if (this.editedOrder.category === 'airtime') {
        // set the network operator in transfer object
        if (type === 'id') {
            if ( transfer.networkOperatorId < 0) {
                error = new Error(0, this.tagErrors.network);
                this.errors.push(error);
                isOk = false;

                if (!this.checkPhone(transfer.phone, this.regExp.afrikpay)) {
                    error = new Error(0, this.tagErrors.phone);
                    this.errors.push(error);
                    isOk = false;
                }

            } else {

                if (!this.checkPhone(transfer.phone, this.regExp[this.networkOperators[transfer.networkOperatorId].slug] )) {
                    error = new Error(0, this.tagErrors.phone);
                    this.errors.push(error);
                    isOk = false;
                }
            }

            if ( typeof transfer.amount === 'undefined' || transfer.amount < 100) {
                error = new Error(0, this.tagErrors.amount);
                this.errors.push(error);
                isOk = false;

            }

            if (isOk) {
                transfer.networkOperator = this.networkOperators[transfer.networkOperatorId].slug;
                transfer.networkOperatorTitle = this.networkOperators[transfer.networkOperatorId].title;
            }

        } else {
            // tslint:disable-next-line: no-shadowed-variable
            const networkOperator = this.networkOperators.find(network => network.slug === transfer.networkOperator);

            if ( typeof networkOperator !== 'undefined' && networkOperator) {

                if (!this.checkPhone(transfer.phone, this.regExp.afrikpay)) {
                    this.errorList.incorrect_phone.push(line + 1);
                    isOk = false;
                }

            } else {

                if (!this.checkPhone(transfer.phone, this.regExp[networkOperator.slug] )) {
                    this.errorList.incorrect_phone.push(line + 1);
                    isOk = false;
                }
            }

            if ( typeof transfer.amount === 'undefined' || transfer.amount < 100) {
                this.errorList.amount_null.push(line + 1);
                isOk = false;

            }
            transfer.networkOperatorTitle = networkOperator.title;
        }
    }


    if (this.editedOrder.category === 'internet') {

        if (!this.checkPhone(transfer.phone, this.regExp.camtel)) {
            if (type === 'id') {
                error = new Error(line + 1, this.tagErrors.phone);
                this.errors.push(error);
            } else {
                this.errorList.incorrect_phone.push(line + 1);
            }
            isOk = false;
        }

    }

    if (this.editedOrder.category === 'bill' && this.editedOrder.provider === 'eneo') {
        if (!this.checkPhone(transfer.phone, this.regExp.afrikpay)) {
            if (type === 'id') {
                error = new Error(line + 1, this.tagErrors.phone);
                this.errors.push(error);
            } else {
                this.errorList.incorrect_phone.push(line + 1);
            }
            isOk = false;
        }

        if (typeof transfer.amount === 'undefined' || transfer.amount < 100) {
            if (type === 'id') {
                error = new Error(line + 1, this.tagErrors.amount);
                this.errors.push(error);
            } else {
                this.errorList.amount_null.push(line + 1);
            }
            isOk = false;
        }

        if (!this.checkMeterId(transfer.meterId)) {
            if (type === 'id') {
                error = new Error(line + 1, this.tagErrors.meterId);
                this.errors.push(error);
            } else {
                this.errorList.incorrect_meter_id.push(line + 1);
            }
            isOk = false;
        }
    }

    return isOk;
  }

  delFile(ord : number){

    if(this.editedOrder.orderSignature){
        this.editedOrder.transfers[ord].document = '';
        this.editedOrder.transfers[ord].documentname = '';
        return;
    }

    if(ord > -1){
        this.editedTransfer.document  = '';
        this.editedTransfer.documentname = '';
        return;
    }

    this.currentTransfer.document = '';
    this.currentTransfer.documentname = '';
  }

  preview(t : Transfer){
    var doc = t.document.split(',');
    var doctype = ((doc[0].split(';'))[0].split(':'))[1];
    var encoded = doc[1];

    console.log(doctype);

    if(doctype.toLocaleLowerCase() === 'application/pdf'){
        this.imgSrc = null;
        this.pdfSrc = encoded;
        this.togglePreview(true);
        return;
    }

    this.imgSrc = [t.document];
    this.pdfSrc = null;
    this.togglePreview(true);

  }

  togglePreview(visibility:boolean){
    (visibility) ? $('#preview').show() : $('#preview').hide();
  }

  addFile($event : any, ord :number){

    var pos = ord > -1 ? 1 : 0;
    pos = this.editedOrder.orderSignature ? 2 : pos;

    const file = $($('input[type=file]')[2]);
    file.parse({
        complete: () => {
            this.getBase64($event).then( (data: string) => { console.log(data);

                if(this.editedOrder.orderSignature){
                    this.editedOrder.transfers[ord].document = data;
                    this.editedOrder.transfers[ord].documentname = $event.target.files[0].name;
                    return;
                }

                if(ord > -1){
                    this.editedTransfer.document  = data;
                    this.editedTransfer.documentname =  $event.target.files[0].name;
                    return;
                }

                this.currentTransfer.document = data;
                this.currentTransfer.documentname =  $event.target.files[0].name;

            })
            .catch( (error) => {
                console.log(error);
            });

            file.val('');
        }
    });
  }

  convertFile($event) {
    const file = $($('input[type=file]')[0]);
    file.parse({
        config: {
            complete: (results) => {
                this.setTransfers(results.data);
            }
        },
        complete: () => {
            this.getBase64($event).then( (data: string) => {
                this.editedOrder.orderSignature = data;
            })
            .catch( (error) => {
                console.log(error);
            });

            file.val('');
        }
    });
  }

   getBase64(event) {
       const me = this;
       const file = event.target.files[0];
       const reader = new FileReader();

       return new Promise((resolve, reject) => {
        reader.onload = () => {
           const encode = reader.result.toString();
           encode.replace(/^data:(.*,)?/, '');
           resolve(encode);
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
       });
  }

  setTransfers(results: []) {
    this.initErrorList();
    results.shift();
    results.pop();

    if (this.editedOrder.category === 'airtime') {

        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < results.length; i++) {
            const data = results[i];

            const t = new Transfer(null);
            t.internalref = data[0];
            // tslint:disable-next-line: radix
            t.amount = parseInt(data[1]);
            t.networkOperator =  data[2];
            t.phone = data[3];
            t.name = data[4];

            // console.log(t);
            this.setProviders(t, 'slug', i + 1);

            this.addTransferFromFile(t);

        }
    } else {

        if (this.editedOrder.provider === 'eneo') {

            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < results.length; i++) {
                const data = results[i];

                const t = new Transfer(null);
                t.internalref = data[0];
                t.meterId = data[1];
                // tslint:disable-next-line: radix
                t.amount = parseInt(data[2]);
                t.phone = data[3];
                t.name = data[4];

                this.setProviders(t, 'slug', i + 1);
                this.addTransferFromFile(t);
           }
        } else {

            if (this.editedOrder.provider === 'canalplus') {
                // tslint:disable-next-line: prefer-for-of
                for (let i = 0; i < results.length; i++) {
                    const data = results[i];

                    const t = new Transfer(null);
                    t.internalref = data[0];
                    t.cardNumber = data[1];
                    t.bundle = data[2];
                    t.phone = data[3];
                    t.name = data[4];

                    this.setProviders(t, 'slug', i + 1);
                    this.addTransferFromFile(t);
               }

            } else {

                if (this.editedOrder.category === 'internet') {

                    // tslint:disable-next-line: prefer-for-of
                    for (let i = 0; i < results.length; i++) {
                        const data = results[i];

                        const t = new Transfer(null);
                        t.internalref = data[0];
                        // tslint:disable-next-line: radix
                        t.amount = parseInt(data[1]);
                        t.phone = data[2];
                        t.name = data[3];

                        this.setProviders(t, 'slug', i + 1);
                        this.addTransferFromFile(t);

                   }
                } else {

                    // tslint:disable-next-line: prefer-for-of
                    for (let i = 0; i < results.length; i++) {
                        const data = results[i];

                        const t = new Transfer(null);
                        t.internalref = data[0];
                        // tslint:disable-next-line: radix
                        t.amount = parseInt(data[1]);
                        t.paymentMethod = data[2];
                        t.phone = data[3];
                        t.name = data[4];

                        this.setProviders(t, 'slug', i + 1);
                        this.addTransferFromFile(t);
                   }
                }
            }
        }
    }

    // if (this.getInputErrorStatus() || this.getFileErrorStatus()) { this.initOrder(); }
  }

  deleteOrder() {
    this.orderService.deleteOrder({userId: this.user.id, orderId: this.editedOrder.id})
    .subscribe( (data: Order) => {
        console.log(data);
        if (data) {
           this.isCreationOk = true;
        } else {
            this.errorOccurs = true;
        }
        this.showLoader = false;
   });
  }

  validateOrder() {
    this.showLoader = true;
    this.errorOccurs = false;
    this.errorMessage = "";
    this.orderService.validateOrder({reference: this.editedOrder.reference, status : true, comment: null})
    .subscribe( (data: any) => {
        this.showLoader = false;
        if (data) {
            this.errorMessage = data.message;
            if(data.code === 200) {
             this.isCreationOk = true;
            }else {
             this.errorOccurs = true;
            }
         } else {
             this.errorOccurs = true;
             this.errorMessage = "error_occured";
         }
        this.showLoader = false;
   });
  }


  rejectOrder() {
    if (this.comments.length < 8) {
        this.tooShortText = true;

    } else {
        this.tooShortText = false;
        const temp = [{
          name : this.user.firstName + ' ' + this.user.lastName,
          comment: this.comments.replace(/(\r\n|\n|\r)/gm, ""),
          date : new Date()
        }];
        this.editedOrder.comment = JSON.stringify(temp);

        // if (this.editedOrder.comment === '' || typeof this.editedOrder.comment === 'undefined') {
        // const temp = [{
        //     name : this.user.firstName + ' ' + this.user.lastName,
        //     comment: this.comments
        // }];
        // this.editedOrder.comment = JSON.stringify(temp);

        // } else {
        //     arrayComments = JSON.parse(this.editedOrder.comment);
        //     arrayComments.push({
        //         name : this.user.firstName + ' ' + this.user.lastName,
        //         comment: this.comments
        //     });

        //     this.editedOrder.comment = JSON.stringify(arrayComments);
        // }

        this.showLoader = true;
        this.errorOccurs = false;
        this.errorMessage = "";
        this.orderService.rejectOrder({reference: this.editedOrder.reference, comment: this.editedOrder.comment})
        .subscribe( (data: any) => {
          if (data) {
              this.errorMessage = data.message;
              if(data.code === 200) {
               this.isCreationOk = true;
              }else {
               this.errorOccurs = true;
              }
           } else {
               this.errorOccurs = true;
               this.errorMessage = "error_occured";
           }
            this.showLoader = false;
       });
      }
  }

  canValidate(){
    if(!this.editedOrder.validations) return true;
    for (const validation of this.editedOrder.validations) {
        if(this.user.id === validation.employee.id){
            return false;
        }
    }
    return true;
  }

  canCreate() {
    return this.editedOrder.status !== 'success' &&
      this.editedOrder.status !== 'pending' &&
      this.editedOrder.status !== 'pending_payment' &&
      this.editedOrder.status !== 'validated';
  }

  createFromThis() {
    this.orderService.createFrom(this.editedOrder.reference);
  }
}
