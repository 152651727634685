import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Transfer} from 'src/classes/Transfer';
import { Error, ErrorFile } from 'src/classes/Error';
import { User } from 'src/classes/User';
import { OrderInit, Order } from 'src/classes/Order';
import { OrderService } from '../order.service';
import { StoreService } from '../store.service';
import { cdnService } from '../cdn.service';
declare var $: any;

@Component({
  selector: 'app-order-create',
  templateUrl: './order-create.component.html',
  styleUrls: ['./order-create.component.css']
})
export class OrderCreateComponent implements OnInit {

    pdfSrc : string;
    imgSrc = null;
    lang : string;

  providers = [
      {
          value: 'canal',
          name: 'Canal plus'
      },
      {
        value: 'eneoprepay',
        name: 'ENEO'
    }
  ];
  bundleList = [
      {
          title: 'Access',
          slug: 'access',
          price: 5000
      },
      {
        title: 'Access plus',
        slug: 'access_plus',
        price: 10000
      },
      {
        title: 'Evasion',
        slug: 'evasion',
        price: 12000
      },
      {
        title: 'Evasion plus',
        slug: 'evasion_plus',
        price: 15000
      }
  ];

  paymentMethodList = [
    {
        title: 'AfrikPay',
        slug: 'afrikpay',
        provider: 'afrikpay'
    },
    {
        title: 'Mobile Money',
        slug: 'mtn_mobilemoney_cm',
        provider: 'mtn'
    },
    {
        title: 'Orange Money',
        slug: 'orange_money_cm',
        provider: 'orange'
    },
    {
        title: 'Express Union Mobile',
        slug: 'express_union_mobilemoney',
        provider: 'eum'
    },
    {
        title: 'Afriland First Bank',
        slug: 'afriland',
        provider: 'bank'
    },
    {
        title: 'EcoBank',
        slug: 'ecobank',
        provider: 'bank'
    },
    {
        title: 'BICEC',
        slug: 'bicec',
        provider: 'bank'
    },
    {
        title: 'CBC',
        slug: 'cbc',
        provider: 'bank'
    },
    {
        title: 'EcoBank',
        slug: 'ecobank',
        provider: 'bank'
    },
    {
        title: 'SGC',
        slug: 'sgc',
        provider: 'bank'
    },
    {
        title: 'SCB',
        slug: 'scb',
        provider: 'bank'
    }
  ];

  networkOperators = [
      {
          title : 'MTN',
          slug: 'mtn'
      },
      {
          title: 'Orange',
          slug: 'orange'
      },
      {
          title: 'Nexttel',
          slug: 'nexttel'
      }
  ];

  internetProviders = [
      {
          name: 'Yoomee',
          value: 'yoomee'
      },
      {
          name: 'Camtel',
          value: 'camtel'
      }
  ];

  regExp = {
      mtn: [/^6((5[0-4]{1}[0-9]{6})|(7[0-9]{1}[0-9]{6})|(8[0-4]{1}[0-9]{6}))$/g],
      orange: [/^6((9[0-9]{7})|5[5-9]{1}[0-9]{6})$/g],
      nexttel: [/^6((6[0-9]{7})|(85[0-9]{6}))$/g],
      camtel: [/^(222|233|242|243)[0-9]{6}$|^(62)[0-9]{7}$/g],
      yoomee : [/^(222|233|242|243)[0-9]{6}$/g],
      afrikpay: [/^237(6((9[0-9]{7})|5[5-9]{1}[0-9]{6})|((9[0-9]{7})|5[5-9]{1}[0-9]{6})|((6[0-9]{7})|(85[0-9]{6}))|(222|233|242|243)[0-9]{6}$|^(62)[0-9]{7}|(222|233|242|243)[0-9]{6})$/g],
      eum: [/^237(6((9[0-9]{7})|5[5-9]{1}[0-9]{6})|((9[0-9]{7})|5[5-9]{1}[0-9]{6})|((6[0-9]{7})|(85[0-9]{6}))|(222|233|242|243)[0-9]{6}$|^(62)[0-9]{7}|(222|233|242|243)[0-9]{6})$/],
      bank : [/[0-9]{15}/g]
  };

  tagErrors = {
      phone: 'incorrect_phone',
      amount: 'amount_null',
      bundle: 'choose_bundle',
      card: 'incorrect_card_number',
      meterId: 'incorrect_meter_id',
      paymentMethod: 'choose_payment',
      network: 'choose_network'
  };


  orderProviderSpecify: string;
  orderTypeList = environment.appConfig.orderTypes;
  currentTransfer = new Transfer(null);
  editedTransfer = new Transfer(null);
  deletedTransfer = new Transfer(null);
  editedRow: number;
  deletedRow: number;
  showImportButton = true;
  result: any;
  temp: number;
  internetProvider: string;
  errors: Error[];
  errorList = new ErrorFile();
  user: User;
  order: OrderInit;
  showLoader = false;
  isCreationOk = false;
  errorOccurs = false;
  documentLoading = false;
  signatureLoading = false;
  orderTemplate : Order;
  displayForm = false;


  constructor(private activatedRoute: ActivatedRoute, private router: Router,
              private store: StoreService, private orderService: OrderService, private cdn : cdnService) { }


  ngOnInit() {
    this.order = new OrderInit();
    this.user = new User(this.store.getUserData()) ;
    this.activatedRoute.paramMap.subscribe(params => {
        this.order.category = params.get('type');
        let template = parseInt(params.get('id'));
        if(template){
          this.order.template = template;
          this.getTemplate();
        }else{
          this.displayForm = true;
        }
        const type = this.orderTypeList.find( type => type.title === this.order.category);
        if (!type || !type.isActive || !this.user.isMaker()) {
            this.router.navigateByUrl('**');
        }
        this.order.typeId = type.id;
    });
    // this.regExp.afrikpay = //[this.regExp.camtel[0], this.regExp.mtn[0], this.regExp.orange[0], this.regExp.nexttel[0]];
    this.regExp.eum = [this.regExp.camtel[0], this.regExp.mtn[0], this.regExp.orange[0], this.regExp.nexttel[0]];
    this.initTransferRow();
    this.initErrorList();
    this.editedRow = -1;
    this.order.transfers = [];
    this.errors = [];
    this.order.amount = 0;
    this.lang = localStorage.getItem('lang');

  }


  getTemplate(){
    this.orderService.get(this.order.template, this.user.token).subscribe((response:any) => {
      if (response.code === 200) {
          response.result.transfers = [] ;
          // response.result.pull = {transfers : this.tem_transfers};
          this.orderTemplate = new Order(response.result);
          this.order.internalref = this.orderTemplate.internalref+" - Copy";
          this.order.object = this.orderTemplate.object;
          this.order.amount = this.orderTemplate.amount;
          this.order.orderSignature = this.orderTemplate.orderSignature;
          this.getTransfers(this.orderTemplate.reference);
      }
    },(error => {
        if(error) console.log(error);
    }) );
  }

  getTransfers(reference){
    this.orderService.getTransfers(reference, 1,  10000000).subscribe((response : any) => {
      if(response.code == 200){
        let content = response.result.content;
        this.orderTemplate.transfers = content;
        // for (let i = 0; i < content.length; i++) {
        //   this.orderTemplate.transfers.push(new Transfer(content[i]))
        // }
        this.orderTemplate.unformat().then(() =>{
          for (let i = 0; i < this.orderTemplate.transfers.length; i++) {
            this.orderTemplate.transfers[i].doneAt = null;
            this.orderTemplate.transfers[i].id = null;
            this.setIdsAndTitle(this.orderTemplate.transfers[i]);
          }
          this.displayForm = true;
          this.order.transfers = this.orderTemplate.transfers;
          if(this.order.orderSignature) this.loadDataScripts();
        })
      }else{
        alert(response.message)
      }
    },(error => {
      if(error) console.log(error);
      alert("Unable to load data - Please check your internet")
  }) );
  }





  getOrderId() {
    const orderTypeObj = this.orderTypeList.find(type => this.order.category === type.title);
    return orderTypeObj.id;
  }

  initTransferRow() {
    this.currentTransfer = new Transfer(null);
    this.currentTransfer.bundleId = -1;
    this.currentTransfer.paymentMethodId = -1;
    this.currentTransfer.networkOperatorId = -1;
  }

  initErrorList() {
      this.errorList = new ErrorFile();

      this.errors = new Array<Error>();
  }

  getInputErrorStatus() {
      if (this.errors.length > 0) {
          return true;
      }
      return false;
  }

  getFileErrorStatus() {
    const arr = Object.values(this.errorList);
    for (let i = 0; i < arr.length; i++) {
        if ( arr[i].length > 0) {
            return true;
        }
    }

    return false;
  }

  getErrorList() {
    return Object.entries(this.errorList) || [];
  }

  initOrder() {
    this.order.amount = 0;
    this.order.transfers = [];
  }

  onBillProviderChange(provider) {
    this.initOrder();
    this.initTransferRow();
    this.initErrorList();
    this.orderProviderSpecify = '';
  }

  checkPhone(phone: any, regExp: RegExp[], method?) {

     let output = false;

     if ( typeof phone === 'undefined') { return output; }
     if ( typeof regExp === 'undefined') { return output; }

     let account = phone;

     if(method){
       if(method == "afrikpay" || method =="express_union_mobilemoney"){console.log(phone.substr(0,3));
         if(!(phone.substr(0,3) == "237")) return false;
         account = phone.substring(3); console.log(account);
       }
     }

    // tslint:disable-next-line: prefer-for-of
     for (let i = 0; i < regExp.length; i++) {
        if (account.toString().match(regExp[i])) {
            return true;
        }
     }
     return output;
  }

  checkCardNumber(num: string) {
     if (typeof num === 'undefined') { return false; }
     return (num.length === 14) ? true : false;
  }

  checkMeterId(num: string) {
    if (typeof num === 'undefined') { return false; }
    return (num.length === 12) ? true : false;
 }

  onInternetProviderChange(provider) {
    this.initOrder();
  }

  saveModif() {
    this.setProviders(this.editedTransfer, 'id', -1);
    const m = this.editedTransfer.amount || 0;
    this.order.amount = this.order.amount - this.temp + m;
    this.order.transfers[this.editedRow] = this.editedTransfer;
    if (!(this.getInputErrorStatus() && this.getFileErrorStatus())) { this.editedRow = -1; }
    this.initTransferRow();
  }

  cancelModif() {
    this.editedRow = -1;
    this.initTransferRow();
    this.initErrorList();
  }

  askDeletion(transferIndex: number) {
    this.deletedRow = transferIndex;
    this.deletedTransfer = this.order.transfers[transferIndex];
    this.temp = this.deletedTransfer.amount;
  }

  deleteTransfer() {
    this.order.transfers.splice(this.deletedRow, 1);
    this.order.amount -= this.temp;
    if (this.order.transfers.length === 0) {
        this.showImportButton = true;
    }
  }

  editTransfer(trannsferIndex: number) {
    this.temp = this.order.transfers[trannsferIndex].amount || 0;
    this.editedTransfer = this.order.transfers[trannsferIndex];
    this.editedRow = trannsferIndex;
  }

  saveTransfer() {
    this.errors = [];

    if (this.setProviders(this.currentTransfer, 'id', -1)) {
         // hide the import button if the add button is used
        this.showImportButton = false;
        this.order.transfers.push(this.currentTransfer);
        const m = this.currentTransfer.amount || 0;
        this.order.amount += m;
        this.initTransferRow();
        this.showImportButton = false;
    }
  }

  addTransferFromFile(transfer: Transfer) {
    this.order.transfers.push(transfer);
    const m = transfer.amount || 0;
    this.order.amount += m;
  }


  setProviders(transfer: Transfer, type: string, line: number) {
    let isOk = true;
    let error;

    if (this.order.provider === 'canal') {

        //The non-id type is for the transactions from file
        // The id type is for transactions comming from input

        if (type === 'id') {
            // Checking data from form inputs

            if ( transfer.bundleId < 0) {
                error = new Error(0, this.tagErrors.bundle);
                this.errors.push(error);
                isOk = false;
            }

            if (!this.checkPhone(transfer.phone, this.regExp.afrikpay)) {
                error = new Error(0, this.tagErrors.phone);
                this.errors.push(error);
                isOk = false;
            }

            if (!this.checkCardNumber(transfer.cardNumber)) {
                error = new Error(0, this.tagErrors.card);
                this.errors.push(error);
                isOk = false;
            }
            if (isOk) {
                // set the bundle in transfer object
                transfer.bundle = this.bundleList[transfer.bundleId].slug;
                transfer.bundleTitle = this.bundleList[transfer.bundleId].title;
                transfer.amount = this.bundleList[transfer.bundleId].price;
            }

        } else {
            // Checking data from file

            // tslint:disable-next-line: no-shadowed-variable
            const bundle = this.bundleList.find(bundle => bundle.slug === transfer.bundle);

            // checking bundle
            if ( typeof bundle !== 'undefined' && bundle) {
                if ( transfer.bundleId < 0) {
                    this.errorList.choose_bundle.push(line + 1);
                    isOk = false;
                }

            } else {
                this.errorList.choose_bundle.push(line + 1);
                isOk = false;
            }

            // checking phone
            if ( typeof transfer.phone !== 'undefined' ) {
                if (!this.checkPhone(transfer.phone, this.regExp.afrikpay)) {
                    this.errorList.incorrect_phone.push(line + 1);
                    isOk = false;
                }
            } else {
                this.errorList.incorrect_phone.push(line + 1);
                isOk = false;
            }

            // checking card number
            if ( typeof transfer.cardNumber !== 'undefined') {
                if (!this.checkCardNumber(transfer.cardNumber)) {
                    this.errorList.incorrect_card_number.push(line + 1);
                    isOk = false;
                }
            } else {
                this.errorList.incorrect_card_number.push(line + 1);
                isOk = false;
            }

            // is everything is okay
            if (isOk) {
                transfer.bundleTitle = bundle.title;
                transfer.amount = bundle.price;
            }
        }

    }

    // tslint:disable-next-line: max-line-length
    if (!(this.order.provider === 'canal' || this.order.provider === 'eneoprepay' || this.order.category === 'airtime' || this.order.category === 'internet')) {
        // set the payment method in transfer object
        if (type === 'id') {
            if ( transfer.paymentMethodId < 0) {
                error = new Error(0, this.tagErrors.paymentMethod);
                this.errors.push(error);
                isOk = false;

                if (!this.checkPhone(transfer.phone, this.regExp.afrikpay)) {
                    error = new Error(line + 1, this.tagErrors.phone);
                    this.errors.push(error);
                    isOk = false;
                }

            } else {

                if (!this.checkPhone(transfer.phone, this.regExp[this.paymentMethodList[transfer.paymentMethodId].provider], this.paymentMethodList[transfer.paymentMethodId].slug )) {
                    error = new Error(0, this.tagErrors.phone);
                    this.errors.push(error);
                    isOk = false;
                }
            }

            if ( typeof transfer.amount === 'undefined' || transfer.amount < 100) {
                error = new Error(0, this.tagErrors.amount);
                this.errors.push(error);
                isOk = false;

            }

            if (isOk) {
                transfer.paymentMethod = this.paymentMethodList[transfer.paymentMethodId].slug;
                transfer.paymentMethodTitle = this.paymentMethodList[transfer.paymentMethodId].title;
            }

        } else {

            // tslint:disable-next-line: no-shadowed-variable
            const paymentMethod = this.paymentMethodList.find(payment => payment.slug === transfer.paymentMethod);

            if ( typeof paymentMethod !== 'undefined' && paymentMethod) {

                if (!this.checkPhone(transfer.phone, this.regExp[paymentMethod.provider], paymentMethod.slug )) {
                    this.errorList.incorrect_phone.push(line + 1);
                    isOk = false;
                }

            } else {

                this.errorList.choose_payment.push(line + 1);
                isOk = false;
            }


            if ( typeof transfer.amount === 'undefined' || transfer.amount < 100) {
                this.errorList.amount_null.push(line + 1);
                isOk = false;

            }

            if (isOk) {
                transfer.paymentMethodTitle = paymentMethod.title;
            }
        }
    }

    if (this.order.category === 'airtime') {
        // set the network operator in transfer object
        if (type === 'id') {
            if ( transfer.networkOperatorId < 0) {
                error = new Error(0, this.tagErrors.network);
                this.errors.push(error);
                isOk = false;

                if (!this.checkPhone(transfer.phone, this.regExp.afrikpay)) {
                    error = new Error(0, this.tagErrors.phone);
                    this.errors.push(error);
                    isOk = false;
                }

            } else {

                if (!this.checkPhone(transfer.phone, this.regExp[this.networkOperators[transfer.networkOperatorId].slug] )) {
                    error = new Error(0, this.tagErrors.phone);
                    this.errors.push(error);
                    isOk = false;
                }
            }

            if ( typeof transfer.amount === 'undefined' || transfer.amount < 100) {
                error = new Error(0, this.tagErrors.amount);
                this.errors.push(error);
                isOk = false;

            }

            if (isOk) {
                transfer.networkOperator = this.networkOperators[transfer.networkOperatorId].slug;
                transfer.networkOperatorTitle = this.networkOperators[transfer.networkOperatorId].title;
            }

        } else {
            // tslint:disable-next-line: no-shadowed-variable
            const networkOperator = this.networkOperators.find(network => network.slug === transfer.networkOperator);

            if ( typeof networkOperator !== 'undefined' && networkOperator) {

                if (!this.checkPhone(transfer.phone, this.regExp.afrikpay)) {
                    this.errorList.incorrect_phone.push(line + 1);
                    isOk = false;
                }

            } else {

                if (!this.checkPhone(transfer.phone, this.regExp[networkOperator.slug] )) {
                    this.errorList.incorrect_phone.push(line + 1);
                    isOk = false;
                }
            }

            if ( typeof transfer.amount === 'undefined' || transfer.amount < 100) {
                this.errorList.amount_null.push(line + 1);
                isOk = false;

            }
            transfer.networkOperatorTitle = networkOperator.title;
        }
    }


    if (this.order.category === 'internet') {

        if (!this.checkPhone(transfer.phone, this.regExp.camtel)) {
            if (type === 'id') {
                error = new Error(line + 1, this.tagErrors.phone);
                this.errors.push(error);
            } else {
                this.errorList.incorrect_phone.push(line + 1);
            }
            isOk = false;
        }

    }

    if (this.order.category === 'bill' && this.order.provider === 'eneoprepay') {
        if (!this.checkPhone(transfer.phone, this.regExp.afrikpay)) {
            if (type === 'id') {
                error = new Error(line + 1, this.tagErrors.phone);
                this.errors.push(error);
            } else {
                this.errorList.incorrect_phone.push(line + 1);
            }
            isOk = false;
        }

        if (typeof transfer.amount === 'undefined' || transfer.amount < 100) {
            if (type === 'id') {
                error = new Error(line + 1, this.tagErrors.amount);
                this.errors.push(error);
            } else {
                this.errorList.amount_null.push(line + 1);
            }
            isOk = false;
        }

        if (!this.checkMeterId(transfer.meterId)) {
            if (type === 'id') {
                error = new Error(line + 1, this.tagErrors.meterId);
                this.errors.push(error);
            } else {
                this.errorList.incorrect_meter_id.push(line + 1);
            }
            isOk = false;
        }
    }

    return isOk;
  }

  delFile(ord : number){

    if(this.order.orderSignature){
        this.order.transfers[ord].document = '';
        this.order.transfers[ord].documentname = '';
        return;
    }

    if(ord > -1){
        this.editedTransfer.document  = '';
        this.editedTransfer.documentname = '';
        return;
    }

    this.currentTransfer.document = '';
    this.currentTransfer.documentname = '';
  }

  previewVoucher(){
    this.pdfSrc = this.order.orderProofBase64;
    this.togglePreview(true);
  }

  togglePreview(visibility:boolean){
    (visibility) ? $('#preview').css('display','flex') : $('#preview').hide();
  }

  randomize(n : number){
    let output = "";
    for(let i = 0; i < n; i++){
      output += Math.floor(Math.random() * 10);;
    }

    return output;

  }

  /**
   *
   * @param $event file input event
   *
   * @param ord trasfer postion in order items array
   */

  addFile($event : any){

    this.documentLoading = true;
    const file = $($('input[type=file]')[2]);
    let d = new Date();
    let filename = this.order.internalref+"_"+this.randomize(5)+d.getTime();



    file.parse({
        complete: () => {
            this.getBase64($event).then( (data: string) => {

              var doc = data.split(',');
              var encoded = doc[1];
              this.order.orderProofBase64 = encoded;

              this.cdn.upload(filename, data, 'pdf').subscribe((data: any) => {
                this.documentLoading = false;
                if(data.code == 200) {
                  this.order.orderProof = data.result.link;
                }else {
                  alert("Error" + data.message);
                }
              },
              (error) => {
                if(error){
                  this.documentLoading = false;
                  alert("An unexpected error occured !");
                }
              })

            })
            .catch( (error) => {
                console.log(error);
            });

            file.val('');
        }
    });
  }

  deleteTransfers(){
    this.order.deleteSignature();
    $("#transfertOrderTable").DataTable().destroy();
  }


  convertFile($event) {
    const inputs = $('input[type=file]');
    const file = inputs.length > 1 ? $(inputs[1]) : $(inputs[0]);
    let d = new Date();
    let filename = this.order.internalref+"_"+this.randomize(5)+d.getTime();
    this.signatureLoading = true;
    file.parse({
        config: {
            complete: (results) => {
              this.getBase64($event).then( (data: string) => {
                  this.cdn.upload(filename, data, 'csv').subscribe((data: any) => {
                    this.signatureLoading = false;
                    if(data.code == 200) {
                      this.order.orderSignature = data.result.link;
                      this.setTransfers(results.data);
                    }else {
                      alert("Error" + data.message);
                    }
                  },
                  (error) => {
                    if(error){
                      console.log(error);
                      alert("An unexpected error occured !");
                    }
                  })
              })
              .catch( (error) => {
                  console.log(error);
              });

              file.val('');
            }
        },
        complete: () => {

        }
    });
  }

   getBase64(event) {
       const me = this;
       const file = event.target.files[0];
       const reader = new FileReader();

       return new Promise((resolve, reject) => {
        reader.onload = () => {
           const encode = reader.result.toString();
           encode.replace(/^data:(.*,)?/, '');
           resolve(encode);
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
       });
  }

  setTransfers(results: []) {
    this.initOrder();
    this.initErrorList();
    results.shift();
    results.pop();

    if (this.order.category === 'airtime') {

        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < results.length; i++) {
            const data = results[i];

            const t = new Transfer(null);
            t.internalref = data[0];
            // tslint:disable-next-line: radix
            t.amount = parseInt(data[1]);
            t.networkOperator =  data[2];
            t.phone = data[3];
            t.name = data[4];

            this.setProviders(t, 'slug', i + 1);

            this.addTransferFromFile(t);
        }
    } else {

        if (this.order.provider === 'eneoprepay') {

            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < results.length; i++) {
                const data = results[i];

                const t = new Transfer(null);
                t.internalref = data[0];
                t.meterId = data[1];
                // tslint:disable-next-line: radix
                t.amount = parseInt(data[2]);
                t.phone = data[3];
                t.name = data[4];

                this.setProviders(t, 'slug', i + 1);
                this.addTransferFromFile(t);
           }
        } else {

            if (this.order.provider === 'canal') {
                // tslint:disable-next-line: prefer-for-of
                for (let i = 0; i < results.length; i++) {
                    const data = results[i];

                    const t = new Transfer(null);
                    t.internalref = data[0];
                    t.cardNumber = data[1];
                    t.bundle = data[2];
                    t.phone = data[3];
                    t.name = data[4];

                    this.setProviders(t, 'slug', i + 1);
                    this.addTransferFromFile(t);
               }

            } else {

                if (this.order.category === 'internet') {

                    // tslint:disable-next-line: prefer-for-of
                    for (let i = 0; i < results.length; i++) {
                        const data = results[i];

                        const t = new Transfer(null);
                        t.internalref = data[0];
                        // tslint:disable-next-line: radix
                        t.amount = parseInt(data[1]);
                        t.phone = data[2];
                        t.name = data[3];

                        this.setProviders(t, 'slug', i + 1);
                        this.addTransferFromFile(t);

                   }
                } else {

                    // tslint:disable-next-line: prefer-for-of
                    for (let i = 0; i < results.length; i++) {
                        const data = results[i];

                        const t = new Transfer(null);
                        t.internalref = data[0];
                        // tslint:disable-next-line: radix
                        t.amount = parseInt(data[1]);
                        t.paymentMethod = data[2];
                        if(t.paymentMethod) t.paymentMethod = t.paymentMethod.toLocaleLowerCase();
                        t.phone = data[3];
                        t.name = data[4];

                        this.setProviders(t, 'slug', i + 1);
                        this.addTransferFromFile(t);
                   }
                }
            }
        }
    }

    if (this.getInputErrorStatus() || this.getFileErrorStatus()) { this.initOrder(); }
    this.loadDataScripts();
  }

  submit() {

    this.errorOccurs = false;
    this.showLoader = true;

    this.order.provider = this.order.provider || this.orderProviderSpecify || this.internetProvider || '';
    var type = (this.order.category === 'airtime' || this.order.category === 'bill') ? this.order.category : 'money';

    this.order.format();

    this.orderService.create(this.order, type)
    .subscribe( (data: {code : number, message : string, order : Order}) => {
         if (data.code == 200) {
            this.isCreationOk = true;
         } else {
             this.errorOccurs = true;
         }
         this.showLoader = false;
    }, (error) =>{
        this.errorOccurs = true;
        this.showLoader = false;
    });
  }


  setIdsAndTitle(transfer: Transfer) {

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.paymentMethodList.length; i++) {
        if (this.paymentMethodList[i].slug === transfer.payment_method) { transfer.paymentMethodId = i; }
    }

    for (let i = 0; i < this.bundleList.length; i++) {
        if (this.bundleList[i].slug === transfer.bundle) {  transfer.bundleId = i; }
    }

    for (let i = 0; i < this.networkOperators.length; i++) {
        if (this.networkOperators[i].slug === transfer.network_operator) { transfer.networkOperatorId = i; }
    }
    const bundle = this.bundleList.find( x => x.slug === transfer.bundle);
    transfer.bundleTitle = (bundle) ? bundle.title : '';

    const method = this.paymentMethodList.find( methodObj => methodObj.slug === transfer.paymentMethod);
    transfer.paymentMethodTitle = (method) ? method.title : '';


    const op = this.networkOperators.find( x => x.slug == transfer.service);
    transfer.networkOperatorTitle = (op) ? op.title : '';


    transfer.networkOperator = transfer.network_operator;
  }


  loadDataScripts() {
    // tslint:disable-next-line: curly
    if (document.getElementById('tableScript')) document.getElementById('tableScript').remove();
    const tableScript = document.createElement('script');
    tableScript.setAttribute('id', 'tableScript');
    tableScript.setAttribute('src', 'assets/js/demo/datatables-demo.js');
    document.body.prepend(tableScript);
  }
}
