import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { User } from 'src/classes/User';
import { Order } from 'src/classes/Order';
import { AuthService } from 'src/app/auth.service';
import { StoreService } from 'src/app/store.service';

@Component({
  selector: 'app-table-finished',
  templateUrl: './table-finished.component.html',
  styleUrls: ['./table-finished.component.css']
})
export class TableFinishedComponent implements OnInit {

  user: User;
  @Input() ordersFinished = new Array<Order>();
  @Input() dateLocale : string;
  @Output() selectOrder: EventEmitter<Order> = new EventEmitter();

  constructor(private store: StoreService) { }

  ngOnInit() {
      this.user = this.store.getUserData();
  }

  showOrder(order: Order) {
      this.selectOrder.emit(order);
  }

}
