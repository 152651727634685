import { HttpService } from './../http.service';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/classes/User';
import { Order } from 'src/classes/Order';
import { OrderService } from '../order.service';
import { Transfer } from 'src/classes/Transfer';
import { stringify } from 'querystring';
import { StoreService } from '../store.service';


@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {

  user: User;
  pendingOrders = new Array<Order>();
  validatedOrders = new Array<Order>();
  rejectedOrders = new Array<Order>();
  pendingPaymentOrders = new Array<Order>();
  orderAll = new Array<Order>();
  loading_report = false;
  selected_order = {id : -1};
  report_sent = false;


  loading = false;
  config = {
    currentPage: 0,
    itemsPerPage: 0,
    totalItems: 0
  };
  page = 1;
  itemsperpage = 10;
  deleting = false;
  responsive = true;

  labels: any = {
    previousLabel: '',
    nextLabel: ''
  };
  lang : string;
  dateLocale : string;

  pending : number;
  validated : number;
  rejected : number;
  pending_payment : number;


  constructor(private store: StoreService, private orderService: OrderService) { }

  ngOnInit() {
    this.user = this.store.getUserData();
    this.getOrders();
    this.countOrders();
  }

  setDateLocale(){
    this.lang = localStorage.getItem('lang');
    if(this.lang == 'en') {
      this.dateLocale = 'MMM d, y, h:mm:ss a';
      return
    }

    this.dateLocale = 'd MMM y, H:mm:ss'
  }

  getOrders() {
    this.loading = true;
    this.orderService.getAll(this.page , this.itemsperpage).subscribe((response: any) => {
      this.loading = false;
      console.log(response);
      if(response.code == 200){
        this.config = {
          currentPage: this.page,
          itemsPerPage: this.itemsperpage,
          totalItems: this.itemsperpage * response.result.totalPage
        };
        this.orderAll = response.result.content;
        var data = response.result.content;

        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < data.length; i++) {

          data[i] = new Order(data[i]);

          // switch (data[i].status) {
          //     case 'rejected' : {
          //         this.rejectedOrders.push(data[i]);
          //         break;
          //     }
          //     case 'validated' : {
          //         this.validatedOrders.push(data[i]);
          //         break;
          //     }
          //     case 'pending' : {
          //         this.pendingOrders.push(data[i]);
          //         break;
          //     }
          //     case 'pending_payment' : {
          //         this.pendingPaymentOrders.push(data[i]);
          //         break;
          //     }
          // }


          // if (i === data.length - 1) {
          //       this.loadDataScripts();
          //   }
        }
      }

    });
  }

  countOrders() {
    let getstats = (s) => this.orderService.getStats(s);
    getstats("validated").subscribe((data:any) => {
      if(data.code == 200){
        this.validated = data.result.orderNumber;
      }
    },(error) => {
      alert("Unknown error! Unable to get your data - Kindly check your internet")
    });

    getstats("rejeted").subscribe((data : any) => {
      if(data.code == 200){
        this.rejected = data.result.orderNumber;
      }
    })

    getstats("pending").subscribe((data : any) => {
      if(data.code == 200){
        this.pending = data.result.orderNumber;
      }
    })

    getstats("pending_payment").subscribe((data : any) => {
      if(data.code == 200){
        this.pending_payment = data.result.orderNumber;
      }
    })
  }

  downloadReport(orderRef: string){
    this.orderService.sendReport(orderRef);
  }
  clear(){
    this.selected_order = {id :-1};
    this.report_sent = false;
  }

  sendReport(order : any){
    this.report_sent = false;
    this.loading_report = true;
    this.selected_order = order;
    this.orderService.report(order.reference).subscribe((response : any) => {
      this.loading_report = false;

      if(response.code == 200){
        this.report_sent = true;
      }else{
        alert(response.code+" - "+response.message)
      }
    })
  }

  pageChange(newPage: number) {
    this.page = newPage;
    this.getOrders();
  }

  // loadDataScripts() {
  //   // tslint:disable-next-line: curly
  //   if (document.getElementById('tableScript')) document.getElementById('tableScript').remove();
  //   const tableScript = document.createElement('script');
  //   tableScript.setAttribute('id', 'tableScript');
  //   tableScript.setAttribute('src', 'assets/js/demo/datatables-demo.js');
  //   document.body.prepend(tableScript);
  // }

}
