
export class PhoneNumber {
  country: string;
  number: string;
  length : number;
  area: string;
  prefix: string;
  line: string;

  constructor(phone : string) {
    this.country = "";
    this.number = phone;
  }

  protectedPhone(){
    return this.number.substr(0,5)+"......."+ this.number.substr(this.number.length-2,2);
  }

  get e164() {
    const num = this.country + this.number;
    return `+${num}`;
  }

  isCorrect() {
    return this.number.length == this.length;
  }
}
