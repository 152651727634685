import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OrderCreateComponent } from './order-create/order-create.component';
import { OrderEditComponent } from './order-edit/order-edit.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HistoryComponent } from './history/history.component';
import { CompanyComponent } from './company/company.component';
import { AdminComponent } from './admin/admin.component';
import { LogoutComponent } from './logout/logout.component';
import {AuditComponent} from './audit/audit.component'
import { guard } from './guard';
import { guard_admin } from './guard_admin';
import { guard_default } from './guard_default';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'dashboard', component: DashboardComponent , canActivate : [guard]},
  { path: 'order/create/:type', component: OrderCreateComponent, canActivate : [guard] },
  { path: 'order/duplicate/:type/:id', component: OrderCreateComponent, canActivate : [guard] },
  { path: 'order/edit/:id', component: OrderEditComponent, canActivate : [guard] },
  { path: 'company/profile', component: CompanyComponent, canActivate : [guard_default]},
  { path: 'company/history', component: HistoryComponent, canActivate : [guard]},
  { path: 'admin/user/manager', component: AdminComponent, canActivate : [guard_admin]},
  { path: 'admin/audit', component: AuditComponent, canActivate : [guard_admin]},
  { path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [ RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
