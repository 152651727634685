import { Component, OnInit } from '@angular/core';
import { User } from 'src/classes/User';
import { AuthService } from '../auth.service';
import { StoreService } from '../store.service';
declare var $ : any;

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  action : boolean;
  user : User;
  showLoader = false;
  loading_employees = false;
  selectedUser : number;
  actionDone = false;
  errorOccurs = false;
  employees: Array<User>;
  dateLocale :string;
  lang : string;

  config : any;
  page = 1;
  itemsperpage = 10;
  deleting = false;
  responsive = true;
  labels: any = {
    previousLabel: '',
    nextLabel: ''
  };

  constructor(private store: StoreService, private auth : AuthService) { }

  ngOnInit() {
    this.lang = localStorage.getItem('lang');
    this.user = new User(this.store.getUserData());
    this.getEmployees();
  }

  do(action : boolean, index ){
    this.action = action;
    this.selectedUser = index;
    $('#approvedModal').modal('show');
    return false;
  }

  setDateLocale(){
    if(this.lang == 'en') {
      this.dateLocale = 'MMM d, y, h:mm:ss a';
      return
    }

    this.dateLocale = 'd MMM y, H:mm:ss'
  }
  getEmployees(){
    this.loading_employees = true;
    this.auth.getEmployees(this.page, this.itemsperpage).subscribe((data:any) =>{
      this.loading_employees = false;
      if(data.code == 200){
        this.config = {
          currentPage: this.page,
          itemsPerPage: this.itemsperpage,
          totalItems: this.itemsperpage * data.result.totalPage
        };
        this.employees = data.result.content;
      }
    })
  }

  confirmAction(){
    this.showLoader = true;
    this.errorOccurs = false;
    this.actionDone = false;
    var user = new User(this.employees[this.selectedUser]);
    this.auth.setUserStatus(user.phone, this.user.phone, this.action).subscribe((data:any)=> {
      if (data) {
        if(data.code === 200) {
         this.actionDone = true;
         this.employees[this.selectedUser] = data.result;
        }else {
         this.errorOccurs = true;
        }
     } else {
         this.errorOccurs = true;
     }
    this.showLoader = false;
    })
  }

  pageChange(newPage: number) {
    this.page = newPage;
    this.getEmployees();
  }

  reset(){
    this.errorOccurs = false;
    this.actionDone = false;
    this.showLoader = true;
  }

}
