import { CompanyInfo } from './CompanyInfo';
import { User } from './User';

export class Company {
  id: number;
  name: string;
  fixedphone: string;
  mobilephone: string;
  email: string;
  companyinfos : CompanyInfo;
  logo: string;
  employees: Array<User>;
  directors: Array<User>;
  accountNumber : string;
  address : string;
  authMethod : {slug : string, verifyCodeApi : string};

  constructor(data : any) {
    this.name = data.name;
    this.id = data.id;
    this.fixedphone = data.fixedPhone;
    this.email = data.email;
    this.mobilephone = data.mobilephone;
    this.logo = data.logo;
    this.employees = data.employees;
    this.directors = data.directors;
    this.authMethod = data.authMethod;
  }

}


