import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from 'src/classes/User';
import { AuthService } from '../auth.service';
import { StoreService } from '../store.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})

export class SidebarComponent implements OnInit {

  currentRoute: string;
  user: User;

  constructor(private route: ActivatedRoute, private store : StoreService) { }

  ngOnInit() {
      this.user = new User(this.store.getUserData());
      const routes = this.route.snapshot.url;
      if (routes.length > 1) {
        this.currentRoute = routes.join("/"); console.log(this.currentRoute);
      } else {
        if (routes.length === 1) {
            this.currentRoute = routes[0] + '';
          }
      }
  }
}
