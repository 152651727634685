<!-- Page Wrapper -->
<div id="wrapper">
    <!-- Sidebar -->
    <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled" id="accordionSidebar">
        <app-sidebar></app-sidebar>
    </ul>

    <!-- Content Wrapper -->
    <div id="content-wrapper" class="d-flex flex-column">

        <!-- Main Content -->
        <div id="content">

            <!-- Topbar -->
            <app-header></app-header>
            <div class="container-fluid">
                <!-- Page Heading -->
                <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h4 class="h4 mb-0 text-gray-800">{{ 'history' | translate}}
                    </h4>

                    <div class="mb-4">
                    </div>
                </div>
                <!-- Stats card -->
                <div class="row">

                    <!-- Earnings (Monthly) Card Example -->
                    <div class="col-xl-3 col-md-6 mb-4" *ngIf="validated > 0">
                        <div class="card border-left-primary shadow h-100 py-2">
                            <div class="card-body">
                                <div class="row no-gutters align-items-center">
                                    <div class="col mr-2">
                                        <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">{{'approved_transfer' | translate}}</div>
                                        <div class="h5 mb-0 font-weight-bold text-gray-800">{{validated}}</div>
                                    </div>
                                    <div class="col-auto">
                                        <i class="fas fa-check-double fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Transfer order non-approved -->
                    <div class="col-xl-3 col-md-6 mb-4" *ngIf="rejected > 0">
                        <a href="#" class="text-decoration-none">
                            <div class="card border-left-danger shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="text-xs font-weight-bold text-danger text-uppercase mb-1">{{'rejected_transfer' | translate}} </div>
                                            <div class="h5 mb-0 font-weight-bold text-gray-800">{{rejected}}</div>
                                        </div>
                                        <div class="col-auto">
                                            <i class="far fa-times-circle fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                    <!-- Transfer order rejected -->
                    <div class="col-xl-3 col-md-6 mb-4" *ngIf="pending > 0">
                        <a href="#" class="text-decoration-none">
                            <div class="card border-left-warning shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">{{'validation_pending' | translate}}</div>
                                            <div class="h5 mb-0 font-weight-bold text-gray-800">{{pending}}</div>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fas fa-spell-check fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>


                    <!-- Transfer order non-approved but not done -->
                    <div class="col-xl-3 col-md-6 mb-4" *ngIf="pending_payment > 0">
                        <a href="#" class="text-decoration-none">
                            <div class="card border-left-info shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="text-xs font-weight-bold text-info text-uppercase mb-1">{{'pending_payment' | translate}}</div>
                                            <div class="h5 mb-0 font-weight-bold text-gray-800">{{pending_payment}}</div>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fas fa-spinner fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>


                <!-- Content Row -->

                <div class="alert alert-info d-flex justify-content-between" *ngIf="!loading_report && report_sent">
                  <div class="flex-full">
                    <span>{{'report_sent' | translate}} : {{selected_order.reference}}</span>
                  </div>
                  <div class="">
                    <a (click) = "clear()" ><i class="fas fa-times-circle"></i></a>
                  </div>
                </div>

                <div class="card shadow mb-4">
                    <!-- Card Header - Dropdown -->
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 class="m-0 font-weight-bold text-primary">
                        </h6> <span></span>
                    </div>
                    <!-- Card Body -->
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-hover table-bordered table-striped" width="100%" cellspacing="0" *ngIf="!loading">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>{{'type' | translate}}</th>
                                        <th>{{'issued_on' | translate}}</th>
                                        <th>{{'exec_started_on' | translate}}</th>
                                        <th>{{'exec_finished_on' | translate}}</th>
                                        <th>{{'ref_nbr' | translate}}</th>
                                        <th>{{'issued_by' | translate}}</th>
                                        <th>{{'Stats' | translate}}</th>
                                        <th>{{'amount' | translate}}</th>
                                        <th>{{'Action' | translate}}</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <th></th>
                                        <th>{{'type' | translate}}</th>
                                        <th>{{'issued_on' | translate}}</th>
                                        <th>{{'exec_started_on' | translate}}</th>
                                        <th>{{'exec_finished_on' | translate}}</th>
                                        <th>{{'ref_nbr' | translate}}</th>
                                        <th>{{'issued_by' | translate}}</th>
                                        <th>{{'transaction_processed' | translate}}</th>
                                        <th>{{'amount' | translate}}</th>
                                        <th>{{'Action' | translate}}</th>
                                    </tr>
                                </tfoot>
                                <tbody>
                                    <tr *ngFor="let order of orderAll | paginate: config; let i=index">
                                        <td>
                                            <i class="fas text-secondary fa-ban" *ngIf="order.status === 'canceled'"></i>
                                            <i class="text-primary fas fa-check-double" *ngIf="order.status === 'validated'"></i>
                                            <i class="text-danger far fa-times-circle" *ngIf="order.status === 'rejected'"></i>
                                            <i class="text-warning fas fa-spell-check " *ngIf="order.status === 'pending'"></i>
                                            <i class="text-info fas fa-spinner " *ngIf="order.status === 'pending_payment'"></i>
                                            <i class="fas fa-check-circle text-success" *ngIf="order.status === 'finished'"></i>
                                        </td>
                                        <td class="text-uppercase">{{order.category | translate}}</td>
                                        <td class="text-uppercase">{{order.createddate | date:dateLocale}}</td>
                                        <td class="text-uppercase">{{order.startedAt | date:dateLocale}}</td>
                                        <td class="text-uppercase">{{order.endedAt | date:dateLocale}}</td>
                                        <td class="text-uppercase">{{order.internalref}}</td>
                                        <td class="text-uppercase">{{order.createdby}}</td>
                                        <td class="text-right text-xs">
                                          <div *ngIf="(order.status === 'finished') || (order.status === 'pending_payment')">
                                            <span class="text-xs badge badge-danger mr-1">{{order.failed}}/{{order.getTransferNbr()}}</span>
                                            <span class="text-xs badge badge-warning mr-1 text-dark">{{order.pending}}/{{order.getTransferNbr()}}</span>
                                            <span class="text-xs badge badge-success">{{order.success}}/{{order.getTransferNbr()}}</span>
                                          </div>
                                        </td>
                                        <td style="text-align: right !important;">XAF {{order.amount | number}} </td>
                                        <td><a  *ngIf="(order.status === 'finished') || (order.status === 'pending_payment')" (click)="downloadReport(order.reference)"  ><span class="text-xs">{{ 'download' | translate}}</span> <i class="fas fa-file-export text-secondary icon-hover ml-2"></i></a> </td>
                                        <td>
                                          <div *ngIf="loading_report && (selected_order.id == order.id)" class="d-flex justify-content-center">
                                            <img  style="width: 4vw; height: auto;" src="../../assets/img/load.gif" />
                                          </div>
                                          <a  *ngIf="((order.status === 'finished') || (order.status === 'pending_payment')) && !loading_report"   (click)="sendReport(order)"  >
                                            <span class="text-xs icon-hover">{{ 'request_report' | translate}}</span>
                                            <i class="fas fa-file-export text-secondary icon-hover ml-2"></i>
                                          </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="d-flex justify-content-end">
                            <span>Pages</span>
                            <pagination-controls

                              [responsive]="responsive"
                              [previousLabel]="labels.previousLabel"
                              [nextLabel]="labels.nextLabel"

                            *ngIf="config" (pageChange)="pageChange($event)" aria-label="Page navigation example" class="my-pagination">
                          </pagination-controls>
                        </div>
                        <div *ngIf="loading" class="d-flex justify-content-center">
                          <img  style="width: 4vw; height: auto;" src="../../assets/img/load.gif" />
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <footer class="sticky-footer bg-white">
            <app-footer></app-footer>
        </footer>
    </div>

</div>
