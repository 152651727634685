import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {  throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { StoreService } from './store.service';


@Injectable({
  providedIn: 'root'
})
export class HttpService {

    private REST_API_SERVER = environment.appConfig.apiUrl;

    constructor(private httpClient: HttpClient, private store : StoreService) { }

    handleError(error: HttpErrorResponse) {
        let errorMessage = 'Unknown error!';
        if (error.error instanceof ErrorEvent) {
          // Client-side errors
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // Server-side errors
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
      }

    public get(url: string, token = null) {
      // tslint:disable-next-line: max-line-length
      const user = this.store.getUserData();
      const t = user ? user.token : token;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          Authorization : 'Bearer ' + t,
          apiuser : environment.apiuser
        })
      };
      return this.httpClient.get(this.REST_API_SERVER + url, httpOptions).pipe(retry(1), catchError(this.handleError));
    }

    public post(url: string, data: string, token = null) {
        // tslint:disable-next-line: max-line-length
        const user = this.store.getUserData();
        const t = user ? user.token : token;
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json',
            Authorization : 'Bearer ' + t,
            apiuser : environment.apiuser
          })
        };
        return this.httpClient.post(this.REST_API_SERVER + url, data, httpOptions).pipe(retry(1), catchError(this.handleError));
    }

    public post_cdn(data: string){
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.httpClient.post('https://sandbox.api.afrikpay.com/api/storage/upload/base64/', data, httpOptions).pipe(retry(1), catchError(this.handleError));
    }
}
