<div class="card shadow mb-4">
    <!-- Card Header - Dropdown -->
    <div class="card-header bg-primary py-1 d-flex flex-row align-items-center justify-content-between">
        <h5  class="m-0 font-weight-bold text-white">{{'approved_transfer' | translate}}</h5>
    </div>
    <!-- Card Body -->
    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-hover table-bordered table-striped" id="dataTableApproved" width="100%" cellspacing="0">
                <thead>
                    <tr>
                      <th></th>
                      <th>{{'issued_on' | translate}}</th>
                      <th>{{'type' | translate}}</th>
                      <th>{{'ref_nbr' | translate}}</th>
                      <th>{{'issued_by' | translate}}</th>
                      <th>{{'nbr_recip' | translate}}</th>
                      <th>{{'amount' | translate}}</th>
                      <th></th>
                    </tr>
                </thead>
                <tfoot>
                    <tr>
                      <th></th>
                      <th>{{'issued_on' | translate}}</th>
                      <th>{{'type' | translate}}</th>
                      <th>{{'ref_nbr' | translate}}</th>
                      <th>{{'issued_by' | translate}}</th>
                      <th>{{'nbr_recip' | translate}}</th>
                      <th>{{'amount' | translate}}</th>
                      <th></th>
                    </tr>
                </tfoot>
                <tbody>
                    <tr *ngFor="let order of validatedOrders; let i = index">
                        <td >{{i+1}}</td>
                        <td class="text-xs">{{order.createddate | date:dateLocale}}</td>
                        <td  class="text-uppercase">{{order.category | translate}} <span *ngIf="order.provider">| {{order.provider}} </span>  </td>
                        <td  class="text-uppercase ">{{order.internalref}} </td>
                        <td  class="text-uppercase ">{{order.createdby}}</td>
                        <td> {{order.getTransferNbr()}} {{'transfer' | translate}}(s) </td>
                        <td  class="text-right ">XAF {{order.amount | number}}</td>
                        <td>
                            <a href="order/edit/{{order.id}}" class=" d-inline-block mr-2 " role="button ">
                                <i class="far text-black-50 fa-edit icon-hover "></i>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
