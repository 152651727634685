import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Order } from 'src/classes/Order';
import { OrderInit } from '../classes/Order';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  pendingOrders = new Array<Order>();
  validatedOrders = new Array<Order>();
  rejectedOrders = new Array<Order>();
  pendingPaymentOders = new Array<Order>();

  constructor(private httpService: HttpService) { }

  getAll(page: number, size : number) {
    return this.httpService.get('biz/organisation/orders/?page='+page+'&size='+size);
  }

  getTransfers(ref: string, page: number, size : number) {
    return this.httpService.get('biz/organisation/order/transfers/?page='+page+'&size='+size+'&orderref='+ref);
  }

  getValidations(ref: string) {
    return this.httpService.get('biz/order/validations/?page=1&size=50&orderref='+ref);
  }

  get(order: any, token : string) {
    return this.httpService.get('biz/order/get/?id='+order);
  }

  create(order: OrderInit, type : string) {
    return this.httpService.post('biz/order/create/', JSON.stringify({type,order}));
  }

  validateOrder(data: {reference: string, status : boolean, comment : string}) {
    return this.httpService.post('biz/order/validate/', JSON.stringify(data));
  }

  updateOrder(order: Order) {
    return this.httpService.post('biz/order/reject',  JSON.stringify(order));
  }

  rejectOrder(data: {reference: string, comment: string}) {
    return this.httpService.post('biz/order/reject/', JSON.stringify(data));
  }

  deleteOrder(data: {userId: number, orderId: number}) {
    return this.httpService.post('biz/order/cancel', JSON.stringify(data));
  }

  getStats(status:string) {
    return this.httpService.get('biz/stats/status/?status='+status);
  }

  sendReport(orderref: string){
    return this.httpService.get('biz/organisation/order/execution/report/?orderref='+orderref);
  }

  setPendingOrders(orders: Array<Order>) {
      this.pendingOrders = orders;
  }

  setValidatedOrders(orders: Array<Order>) {
    this.validatedOrders = orders;
  }

  setRejectedOrders(orders: Array<Order>) {
    this.rejectedOrders = orders;
  }

  setPendingPaymentOrders(orders: Array<Order>) {
    this.pendingPaymentOders = orders;
  }

  getPendingOrders() {
    return this.pendingOrders;
  }

  getValidatedOrders() {
    return this.validatedOrders;
  }

  getRejectedOrders() {
    return this.rejectedOrders;
  }

  getPendingPaymentOrders() {
    return this.pendingPaymentOders;
  }


  getTransactions(page: number, size : number) {
    return this.httpService.get('biz/organisation/audit/transactions/?page='+page+'&size='+size);
    // https://sandbox.api.afrikpay.com/api/biz/organisation/audit/transactions/?page=1&size=20
  }

  report(ref){
    // return this.httpService.post('biz/organisation/audit/transactions/',  JSON.stringify({orderref : ref}));
    return this.httpService.get('biz/organisation/order/transfers/report/?orderref='+ref);
  }

  createFrom(reference: string) {
    return this.httpService.post('/biz/organisation/order/createfrom/', JSON.stringify({orderref: reference}));
  }
}
